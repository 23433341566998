import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Route, Router } from '@angular/router';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from '../../shared/AppConstants';
import { ErrorPageComponent } from '../error-page/error-page.component';
import { MedicalInfoTrakings } from '../_models/medical-info-tracking';
import { Portal, PortalProfiles, PortalUserManagement, SearchComponents } from '../_models/portals';
import { fileprops, UserLoginResult, UserParams, UserProfiles } from '../_models/user';
import { AuthenticationService } from './authentication.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  user: UserLoginResult = {};
  interval: any;
  intervalClear: any;
  deviceInfo: DeviceInfo;
  portal: Portal = {
    PortalComponents: []
  };
  userProfiles: Array<UserProfiles>;

  private featureProfileSubject: BehaviorSubject<any>;
  public featureProfileObserver: Observable<any>;

  public selectedProfileSubject: BehaviorSubject<any>;
  public selectedProfileObserver: Observable<any>;

  private selectedFileURLSubject: BehaviorSubject<any>;
  public selectedFileURLObserver: Observable<any>;

  public currentURLSubject: BehaviorSubject<any>;
  public currentURLObserver: Observable<any>;

  constructor(private http: HttpClient,
    private utilityService: UtilityService,
    private authService: AuthenticationService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private titleService: Title) {
    this.featureProfileSubject = new BehaviorSubject<any>([]);
    this.featureProfileObserver = this.featureProfileSubject.asObservable();

    this.selectedProfileSubject = new BehaviorSubject<any>([]);
    this.selectedProfileObserver = this.selectedProfileSubject.asObservable();

    this.selectedFileURLSubject = new BehaviorSubject<any>([]);
    this.selectedFileURLObserver = this.selectedFileURLSubject.asObservable();

    this.currentURLSubject = new BehaviorSubject<any>([]);
    this.currentURLObserver = this.selectedProfileSubject.asObservable();

  }

  public get userProfileFeatureList(): any {
    return this.featureProfileSubject.value;
  }

  setUserProfileFeaturesList(featuresList: any) {
    this.featureProfileSubject.next(featuresList);
  }

  selectedProfileFeaturesList(): any {
    return this.featureProfileSubject.value;
  }

  setselectedProfile(selectedProfile: any) {
    this.selectedProfileSubject.next(selectedProfile);
  }

  public get selectProfile(): any {
    return this.selectedProfileSubject.value;
  }



  setcurrentURL(currentUrl: any) {
    this.currentURLSubject.next(currentUrl);
  }

  public get getCurrentURL(): any {
    return this.currentURLSubject.value;
  }



  setFeatures(selectedFromPopup: boolean = false): void {
    let navigateURL: string = this._setFeatures();
    if (selectedFromPopup) {
      // this.router.navigate([navigateURL]);
      this.router.navigate([navigateURL])
        .then(() => {
          // window.location.reload();
        });
      //window.location.href = navigateURL;
    }
  }

  _setFeatures(): string {
    let profileFeature = this.featureProfileSubject.value;

    let isHomeExist = profileFeature.findIndex((profile: any) => profile.Code == 'SR_MI' || profile.Code == 'RQ_AE');
    if (isHomeExist != -1) {
      return '/home';
    }

    let isSMIExist = profileFeature.findIndex((profile: any) => profile.Code == 'SR_MI');
    if (isSMIExist != -1) {
      return '/find-medical-info';
    }

    let isRMIExist = profileFeature.findIndex((profile: any) => profile.Code == 'RQ_MI');
    if (isRMIExist != -1) {
      return '/request-mi';
    }

    let isFMSLExist = profileFeature.findIndex((profile: any) => profile.Code == 'F_MSL');
    if (isFMSLExist != -1) {
      return '/find-msl';
    }

    let isAEExist = profileFeature.findIndex((profile: any) => profile.Code == 'RQ_AE');
    if (isAEExist != -1) {
      return '/report-ae';
    }

    let isPCExist = profileFeature.findIndex((profile: any) => profile.Code == 'RQ_PC');
    if (isPCExist != -1) {
      return '/report-pc';
    }


    let isNAExist = profileFeature.findIndex((profile: any) => profile.Code == 'NS_AN');
    if (isNAExist != -1) {
      return '/news-announcements';
    }

    let isECExist = profileFeature.findIndex((profile: any) => profile.Code == 'EV_CF');
    if (isECExist != -1) {
      return '/events-conferences';
    }

    let isOPExist = profileFeature.findIndex((profile: any) => profile.Code == 'OR_PD');
    if (isOPExist != -1) {
      return '/our-products';
    }

    let isRESExist = profileFeature.findIndex((profile: any) => profile.Code == 'RESO');
    if (isRESExist != -1) {
      return '/resources';
    }

    //for (const element of profileFeature) {
    //  if (element.Code == 'RQ_MI' && element.IS_ACTIVE) {
    //    if (navigateURL == '') {
    //      navigateURL = '/request-mi';
    //    }
    //    return navigateURL;
    //  }
    //  else if (element.Code == 'F_MSL' && element.IS_ACTIVE) {
    //    if (navigateURL == '') {
    //      navigateURL = '/find-msl';
    //    }
    //    return navigateURL;
    //  }
    //  else if (element.Code == 'SR_MI' && element.IS_ACTIVE) {
    //    if (navigateURL == '') {
    //      navigateURL = '/find-medical-info';
    //    }
    //    return navigateURL;
    //  }
    //  else if (element.Code == 'RQ_AE' && element.IS_ACTIVE) {
    //    if (navigateURL == '') {
    //      navigateURL = '/report-ae';
    //    }
    //    return navigateURL;
    //  }
    //  else if (element.Code == 'RQ_PC' && element.IS_ACTIVE) {
    //    if (navigateURL == '') {
    //      navigateURL = '/report-pc';
    //    }
    //    return navigateURL;
    //  }
    //  else if (element.Code == 'NS_AN' && element.IS_ACTIVE) {
    //    if (navigateURL == '') {
    //      navigateURL = '/news-announcements';
    //    }
    //    return navigateURL;
    //  }
    //  else if (element.Code == 'EV_CF' && element.IS_ACTIVE) {
    //    if (navigateURL == '') {
    //      navigateURL = '/events-conferences';
    //    }
    //    return navigateURL;
    //  }
    //  else if (element.Code == 'OR_PD' && element.IS_ACTIVE) {
    //    if (navigateURL == '') {
    //      navigateURL = '/our-products';
    //    }
    //    return navigateURL;
    //  }
    //  else if (element.Code == 'RESO' && element.IS_ACTIVE) {
    //    if (navigateURL == '') {
    //      navigateURL = '/resources';
    //    }
    //    return navigateURL;
    //  }
    //}
    return '';
  }

  navigateTo(code: string): string {
    let url: string = ''
    if (code == 'RQ_MI') {
      url = '/request-mi';
    }
    else if (code == 'F_MSL') {
      url = '/find-msl';
    }
    else if (code == 'SR_MI') {
      url = '/find-medical-info';
    }
    else if (code == 'NS_AN') {
      url = '/news-announcements';
    }
    else if (code == 'RQ_AE') {
      url = '/report-ae';
    }
    else if (code == 'RQ_PC') {
      url = '/report-pc';
    }
    else if (code == 'EV_CF') {
      url = '/events-conferences';
    }
    else if (code == 'OR_PD') {
      url = '/our-products';
    }
    else if (code == 'RESO') {
      url = '/resources';
    }
    else if (code == 'ST_MP') {
      url = '/sitemap';
    }
    return url;
  }

  setRouterUrl(url: string): void {
    localStorage.setItem('routeurl', url);
  }

  getRouterUrl(): string {
    return localStorage.getItem('routeurl') ?? "";
  }

  getPortal(): Observable<Portal> {
    //return this.http.get<Portal>(AppConstants.PortalApiBaseUrl + '/api/Home/GetPortal/' + AppConstants.EnvironmentId);
    return this.http.get(AppConstants.PortalApiBaseUrl + '/api/Home/GetPortal/' + AppConstants.EnvironmentId);
  }
  GetPortalIdleSessionTimes(): Observable<PortalUserManagement> {
    //return this.http.get(AppConstants.PortalApiBaseUrl + '/api/Home/GetPortalSessionTimeOut/' + AppConstants.EnvironmentId);
    return this.http.get<PortalUserManagement>(AppConstants.PortalApiBaseUrl + '/api/Home/GetPortalSessionTimeOut/' + AppConstants.EnvironmentId);
  }

  getMedInfoTracking(product: string, indication: string, keyword: string, document_store_ID: string, data: any, category: string): Observable<any> {
    // let document_file_store_ID;
    // if (!document_store_ID || document_store_ID === null) {
    //   document_file_store_ID: null;
    // }
    // else {
    //   document_file_store_ID: document_store_ID;
    // }

    this.deviceInfo = this.authService.getDeviceInfo();
    let browserInfo: string = '';
    let isMobile: boolean = false;
    let isTablet: boolean = false;
    let isDesktopDevice: boolean = false;
    if (this.deviceInfo) {
      browserInfo = this.deviceInfo.browser + " " + this.deviceInfo.browser_version;

      isMobile = this.deviceService.isMobile();
      isTablet = this.deviceService.isTablet();
      isDesktopDevice = this.deviceService.isDesktop();
    }

    let profileid: string = '';
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo.PK_ENV_PROFILE_ID) {
      profileid = profileinfo.PK_ENV_PROFILE_ID;
    }

    if (!profileid) {
      profileid = localStorage.getItem('RT_ID') ?? '';
    }

    let medInfoTracking: MedicalInfoTrakings = {
      FK_ENVIRONMENT_ID: AppConstants.EnvironmentId,
      PRODUCT_NAME: product,
      INDICATION: indication,
      KEYWORD: keyword,
      FK_DOCUMENT_STORE_ID: document_store_ID ?? null,
      PAGE_TITLE: this.titleService.getTitle(),
      BROWSER_LANGUAGE: navigator.language,
      USER_AGENT: browserInfo ?? '',
      TIME_STAMP: new Date(),
      LOGGED_IN_USER_ID: AppConstants.PkUserId,
      FK_ENV_PROFILE_ID: profileid ?? '',
      DEVICE_INFO: this.deviceService.isDesktop() ? this.deviceInfo.os_version : this.deviceInfo.device,
      Version: data.Version,
      VersionId: data.VersionId,
      DocFileKey: data.DocFileKey,
      FileType: data.FileType,
      DeviceType: isMobile ? 'Mobile Devices' : isTablet ? 'Tabs' : isDesktopDevice ? 'Desktops' : 'Others',
      Category: category
    }

    //return this.http.post<any>(AppConstants.PortalApiBaseUrl + '/api/Auth/MedicalInfoTracking', medInfoTracking);
    return this.http.post(AppConstants.PortalApiBaseUrl + '/api/Auth/MedicalInfoTracking', medInfoTracking);
  }

  //getUserProfiles(): Observable<Array<UserProfiles>> {
  //  return this.http.get<Array<UserProfiles>>(AppConstants.PortalApiBaseUrl + '/api/Environment/GetUserDetails/' + AppConstants.EnvironmentId);
  //}

  getUserProfiles(): Observable<Array<PortalProfiles>> {
    return this.http.get<Array<PortalProfiles>>(AppConstants.PortalApiBaseUrl + '/api/Environment/GetPortalProfiles/' + AppConstants.EnvironmentId);
  }

  getUserProfileFeatures(searchComponents: SearchComponents): Observable<Array<any>> {
    searchComponents.EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<Array<any>>(AppConstants.PortalApiBaseUrl + '/api/Home/GetPortalComponentsByProfile', searchComponents)
  }

  generateGuestToken(): Observable<UserLoginResult> {
    let browserName = this.authService.detectBrowserName();
    const userParams: UserParams = {
      PAGE_TITLE: this.titleService.getTitle(),
      BROWSER_LANGUAGE: navigator.language,
      USER_AGENT: browserName,
      TIME_STAMP: new Date(),
      EnvironmentID: AppConstants.EnvironmentId
    };
    return this.http.post<UserLoginResult>(AppConstants.PortalApiBaseUrl + '/api/Auth/GenerateTokenForGuestUser', userParams)
      .pipe(map(x => {
        if (x) {
          if (x.Errors && x.Errors.length > 0) {
            return x;
          }
          this.user = x;
          // this.userAccountSubject.next(x);
          this.setGuestLocalStorage(x);
        }
        return x;
      }));

  }

  setGuestLocalStorage(x: UserLoginResult) {
    localStorage.setItem('access_token', x.AccessToken ?? '');
    localStorage.setItem('refresh_token', x.RefreshToken ?? '');
    this.utilityService.resetSessionExpired();
    localStorage.setItem("lastAction", Date.now().toString());
    this.authService.initListener();
    this.authService.initInterval();
    this.authService.startRefreshTokenTimer();
  }

  setFileURL(url?: string, filename?: string) {
    let FileProps: fileprops = {
      url: url,
      name: filename
    }
    this.selectedFileURLSubject.next(FileProps);
  }

}

