import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from '../../shared/AppConstants';
import { ACCESSTYPES, PROFILETYPES, RegistrationErrors, USERTYPES } from '../_enums/constant-types.enum';
import { AccountActivationResponse } from '../_models/account-activation-response';
import { APIResponse } from '../_models/apiResponse';
import { CookieTrackings } from '../_models/cookie-trackings';
import { PASSWORD_POLICY } from '../_models/password-policy';
import { Portal, PortalProfiles, PortalUserManagement, SEOtags } from '../_models/portals';
import { RegisterResponse } from '../_models/register-response';
import { AppUserInfo, ChangePasswordRequest, ResetPasswordRequest, User, UserLoginResult, UserParams } from '../_models/user';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { UtilityService } from './utility.service';
import * as signalR from '@microsoft/signalr';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  user: UserLoginResult = {};
  interval: any;
  intervalClear: any;
  portal: Portal = {
    PortalComponents: []
  };
  idleIntervalClear: any;

  //portalUsermanagement: PortalUserManagement;

  private refreshTokenTimeout: ReturnType<typeof setTimeout>;

  private userAccountSubject: BehaviorSubject<UserLoginResult>;
  public userAccount: Observable<UserLoginResult>;

  public loginStatus = new BehaviorSubject<boolean>(this.isUserLoggedIn());
  public UserName = new BehaviorSubject<string>(this.getLoginUserName());
  public UserFullName = new BehaviorSubject<string>(this.getLoginUserFullName());
  public hcpDetails = new BehaviorSubject<AppUserInfo>(this.getLoginHCPDetails());

  private currentFeatureCodeSubject: BehaviorSubject<any>;
  public currentFeatureCodeObserver: Observable<any>;

  //private _user= new BehaviorSubject<UserLoginResult>(null);
  //user$: Observable<UserLoginResult> = this._user.asObservable();

  private eventSubject: Subject<boolean> = new Subject<boolean>();
  public readonly statusChanged$: Observable<boolean> = this.eventSubject.asObservable();
  platformId: any;
  currentFeatureCode: string = '';

  deviceInfo: DeviceInfo;

  constructor(private http: HttpClient,
    private encrDecrService: EncryptDecryptService,
    private utilityService: UtilityService,
    private titleService: Title,
    private deviceService: DeviceDetectorService,
    private _cookieService:CookieService,
    private route: Router, @Inject(PLATFORM_ID) platformId: any
  ) {
    this.platformId = platformId;
    let user: UserLoginResult = <UserLoginResult>{};
    this.userAccountSubject = new BehaviorSubject<UserLoginResult>(user);
    this.userAccount = this.userAccountSubject.asObservable();
    this.currentFeatureCodeSubject = new BehaviorSubject<any>([]);
    this.currentFeatureCodeObserver = this.currentFeatureCodeSubject.asObservable();
  }

  // setLoginLocalStorageInterval() {
  //   this.interval = setInterval(() => {
  //     this.clearLocalStorageInterval(); 
  //   }, 1000 * AppConstants.LocalStorageTimeOutInSeconds);
  // }

  // clearLocalStorageInterval()
  // {    
  //   clearInterval(this.interval);
  //   this.clearLocalStorage();
  //   this.route.navigate(['/'])
  //   .then(() => {
  //     window.location.reload();
  //   });
  // }

  public get userAccountValue(): UserLoginResult {
    return this.userAccountSubject.value;
  }

  getFeaturesSEOTagDetails(code: string): Observable<SEOtags> {
    let pageSEOTags = {
      FeatureCode: code,
      PortalCode: AppConstants.EnvironmentId
    };
    return this.http.post<SEOtags>(AppConstants.PortalApiBaseUrl + '/api/Auth/GetFeaturePageSEOTagDetails', pageSEOTags);
  }

  getPasswordPolicy(): Observable<PASSWORD_POLICY> {
    return this.http.get<PASSWORD_POLICY>(AppConstants.PortalApiBaseUrl + '/api/Auth/GetPassWordPolicy' + '/HCP');
  }

  getUserAccount(userParams: UserParams): Observable<User> {
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/GetUserAccount', userParams);
  }

  forgotPassword(userParams: UserParams): Observable<User> {
    let browserName = this.detectBrowserName();

    userParams.URI = this.getResetPasswordURI();
    userParams.PAGE_TITLE = this.titleService.getTitle();
    userParams.BROWSER_LANGUAGE = navigator.language;
    userParams.USER_AGENT = browserName;
    userParams.TIME_STAMP = new Date();
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    userParams.PROF_TYPE_CODE = PROFILETYPES.ENV_PORTAL;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/ForgotPassword', userParams);
  }

  resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<User> {
    let browserName = this.detectBrowserName();

    resetPasswordRequest.PAGE_TITLE = this.titleService.getTitle();
    resetPasswordRequest.BROWSER_LANGUAGE = navigator.language;
    resetPasswordRequest.USER_AGENT = browserName;
    resetPasswordRequest.TIME_STAMP = new Date();
    resetPasswordRequest.EnvironmentID = AppConstants.EnvironmentId;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/ResetPassword', resetPasswordRequest);
  }

  changePassword(changePasswordRequest: ChangePasswordRequest): Observable<User> {
    changePasswordRequest.PORTAL_CODE = AppConstants.EnvironmentId;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/ChangePassword', changePasswordRequest);
  }

  accountDeactivation(userParams: UserParams): Observable<APIResponse> {
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    return this.http.post<APIResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/AccountDeactivation', userParams);
  }

  accountActivation(userParams: UserParams): Observable<AccountActivationResponse> {
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    userParams.PROF_TYPE_CODE = PROFILETYPES.ENV_PORTAL;
    return this.http.post<AccountActivationResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/AccountActivation', userParams);
  }

  RegisteredEmailActivation(userParams: UserParams): Observable<AccountActivationResponse> {
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    return this.http.post<AccountActivationResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/RegisteredEmailActivation', userParams);
  }

  accountActivationUsingLink(userParams: UserParams): Observable<AccountActivationResponse> {
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    userParams.PROF_TYPE_CODE = PROFILETYPES.ENV_PORTAL;
    return this.http.post<AccountActivationResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/AccountActivationUsingLink', userParams);
  }

  resendActivation(userParams: UserParams): Observable<boolean> {
    userParams.URI = this.getURI();
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    userParams.PROF_TYPE_CODE = PROFILETYPES.ENV_PORTAL;
    return this.http.post<boolean>(AppConstants.PortalApiBaseUrl + '/api/Auth/ResendActivation', userParams);
  }

  resendActivationLink(userParams: UserParams): Observable<boolean> {
    userParams.URI = this.getURI();
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    return this.http.post<boolean>(AppConstants.PortalApiBaseUrl + '/api/Auth/ResendActivationLink', userParams);
  }

  resendResetPasswordLink(userParams: UserParams): Observable<boolean> {
    let browserName = this.detectBrowserName();

    userParams.URI = this.getResetPasswordURI();
    userParams.PAGE_TITLE = this.titleService.getTitle();
    userParams.BROWSER_LANGUAGE = navigator.language;
    userParams.USER_AGENT = browserName;
    userParams.TIME_STAMP = new Date();
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    return this.http.post<boolean>(AppConstants.PortalApiBaseUrl + '/api/Auth/ResendResetPasswordLink', userParams);
  }

  resendUpdateRegisteredEmailLink(userParams: UserParams): Observable<boolean> {
    let browserName = this.detectBrowserName();

    userParams.PAGE_TITLE = this.titleService.getTitle();
    userParams.BROWSER_LANGUAGE = navigator.language;
    userParams.USER_AGENT = browserName;
    userParams.TIME_STAMP = new Date();
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    return this.http.post<boolean>(AppConstants.PortalApiBaseUrl + '/api/Auth/ResendUpdateRegisteredEmailLink', userParams);
  }

  userRegister(model: User): Observable<RegisterResponse> {
    model.URI = this.getURI(model.USER_ID);
    model.EnvironmentID = AppConstants.EnvironmentId;
    model.USER_TYPE_CODE = USERTYPES.EXTERNAL;
    model.PROF_TYPE_CODE = PROFILETYPES.ENV_PORTAL;

    return this.http.post<RegisterResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/Register', model);
  }

  editProfile(model: User): Observable<UserLoginResult> {
    model.URI = this.getURI(model.USER_ID);
    model.EnvironmentID = AppConstants.EnvironmentId;
    return this.http.post<UserLoginResult>(AppConstants.PortalApiBaseUrl + '/api/Auth/EditProfile', model);
  }

  updateRegisteredEmail(userParams: UserParams): Observable<User> {
    userParams.URI = this.getURI();
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/UpdateRegisteredEmail', userParams);
  }

  overwriteRegisteredEmail(userParams: UserParams): Observable<User> {
    userParams.URI = this.getURI();
    userParams.EnvironmentID = AppConstants.EnvironmentId;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/OverwriteRegisteredEmail', userParams);
  }

  // updateRegisteredEmail(userParams: UserParams): Observable<AccountActivationResponse> {
  //   return this.http.post<AccountActivationResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/UpdateRegisteredEmail', userParams);
  // }

  detectBrowserName() {
    let userAgent = window.navigator.userAgent.toLowerCase();
    let tem;
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }

  login(username: string, password: string, profileid: string): Observable<UserLoginResult> {
    let browserName = this.detectBrowserName();

    const userParams: UserParams = {
      USER_ID: username,
      PASSWORD: password,
      PAGE_TITLE: this.titleService.getTitle(),
      BROWSER_LANGUAGE: navigator.language,
      USER_AGENT: browserName,
      TIME_STAMP: new Date(),
      EnvironmentID: AppConstants.EnvironmentId,
      PK_PROFILE_ID: profileid
    };

    return this.http.post<UserLoginResult>(AppConstants.PortalApiBaseUrl + '/api/Auth/Login', userParams)
      .pipe(map(x => {
        if (x) {
          if (x.Errors && x.Errors.length > 0) {
            x.Errors.forEach(error => {
              if (error == RegistrationErrors.MultiBrowserLogin) {
                localStorage.setItem('cpappuserinfo', JSON.stringify(x));
              }
            });
            return x;
          }
          this.user = x;
          this.userAccountSubject.next(x);
          this.setLocalStorage(x);
          this.utilityService.setEnv();
          this.loginStatus.next(true);
        }
        return x;
      }));
  }

  sspLogin(): Observable<UserLoginResult> {
    return this.login(this.utilityService.sspUN, this.utilityService.sspPD, "");
  }

  getDeviceInfo(): DeviceInfo {
    let deviceinformation: DeviceInfo
    return deviceinformation = this.deviceService.getDeviceInfo();
  }

  cookieTracking(FeatureCode: string): Observable<any> {
    this.deviceInfo = this.getDeviceInfo();
    let browserInfo: string = '';
    if (this.deviceInfo) {
      browserInfo = this.deviceInfo.browser + " " + this.deviceInfo.browser_version;
    }

    let profileid: string = '';
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo.PK_ENV_PROFILE_ID) {
      profileid = profileinfo.PK_ENV_PROFILE_ID;
    }

    if (!profileid) {
      profileid = localStorage.getItem('RT_ID') ?? '';
    }

    let cookieTracking: CookieTrackings = {
      FK_ENVIRONMENT_ID: AppConstants.EnvironmentId,
      PAGE_TITLE: this.titleService.getTitle(),
      BROWSER_LANGUAGE: navigator.language,
      USER_AGENT: browserInfo ?? '',
      TIME_STAMP: new Date(),
      LOGGED_IN_USER_ID: this.user.PK_UserId ?? "",
      FEATURE_CODE: FeatureCode,
      FK_ENV_PROFILE_ID: profileid ?? '',
      DEVICE_INFO: this.deviceService.isDesktop() ? this.deviceInfo.os_version : this.deviceInfo.device
    }

    //return this.http.post<any>(AppConstants.PortalApiBaseUrl + '/api/Auth/CookieTracking', cookieTracking);
    return this.http.post(AppConstants.PortalApiBaseUrl + '/api/Auth/CookieTracking', cookieTracking);
  }

  getValues(): Observable<string[]> {
    return this.http.get<string[]>(AppConstants.PortalApiBaseUrl + '/api/values');
  }

  logout() {
    this.clearLocalStorage();
    //this.logoutResponse();
  }

  multiLogout = (logoutreason: any) => {
    if ((logoutreason == true)) {
      logoutreason = localStorage.getItem('lr') ?? "";
      localStorage.removeItem('lr');
    }
    let IsSessionTimeOut = true;
    this.logoutResponse(IsSessionTimeOut, logoutreason).subscribe({
      next: (response) => {
        console.log('logout-res-logout: ', response);
        this.logout();
        this.stopRefreshTokenTimer();
        this.loginStatus.next(false);
        this.utilityService.isProfileSelection = true;
        this.utilityService.actionOnSelfQualification(false);
        this.UserFullName.next("");
        this.route.navigate(['/'])
          .then(() => {
            if (isPlatformBrowser(this.platformId)) {
              //window.location.reload();
            }

          });

      },
      error: (err) => {
        console.log('logout-err: ', err);
      }
    });

  }

  logoutResponse(SessionTimeOut: boolean, logoutReason: string = ''): Observable<any> {
    const lsItem: any = localStorage.getItem('cpappuserinfo');
    if (!lsItem) {
      return of(true);
    }
    const user = JSON.parse(lsItem);

    let browserName = this.detectBrowserName();
    const userParams: UserParams = {
      USER_ID: user.UserID,
      PAGE_TITLE: this.titleService.getTitle(),
      BROWSER_LANGUAGE: navigator.language,
      USER_AGENT: browserName,
      TIME_STAMP: new Date(),
      IsSessionTimeOut: SessionTimeOut,
      EnvironmentID: AppConstants.EnvironmentId,
      PK_TokenID: localStorage.getItem('pk_refresh_token') ?? '',
      LogoutReason: logoutReason ?? '',
    };
    return this.http.post<any>(AppConstants.PortalApiBaseUrl + '/api/Auth/logout', userParams);
  }

  isUserLoggedIn(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const isloggedin: string = localStorage.getItem('isLoggedIn') ?? '';
      if (isloggedin && isloggedin == 'true') {
        this.loginStatus.next(true);
        return true;
      }
      this.loginStatus.next(false);
      return false;
    }
    return false;
  }

  getLoginUserName(): string {
    if (isPlatformBrowser(this.platformId)) {
      let objUser = localStorage.getItem('cpappuserinfo');
      if (objUser) {
        this.user = JSON.parse(objUser);
        return this.user.LAST_NAME ?? "";
      }
    }
    return "";
  }
  getLoginUserFullName(): string {
    //if (isPlatformBrowser(this.platformId)) {
    let objUser = localStorage.getItem('cpappuserinfo');
    if (objUser) this.user = JSON.parse(objUser);
    //}

    return this.user.UserFullName ?? "";
  }
  getLoginHCPDetails(): AppUserInfo {
    if (isPlatformBrowser(this.platformId)) {
      let objUser = localStorage.getItem('cpappuserinfo');
      if (objUser) this.user = JSON.parse(objUser);
    }
    return this.user;
  }

  get isLoggedIn() {
    return this.loginStatus.asObservable();
  }

  get currentUserName() {
    return this.UserName.asObservable();
  }
  get currentUserFullName() {
    return this.UserFullName.asObservable();
  }

  get getLoggedInHCPDetails() {
    return this.hcpDetails.asObservable();
  }

  getURI(userid?: string): string {
    //return this.getWebBaseUrl() + AppConstants.PortalSuffix + "/account-verify?";
    return this.getWebBaseUrl() + "/account-verify?";
  }

  getResetPasswordURI(userid?: string): string {
    //return this.getWebBaseUrl() + AppConstants.PortalSuffix + "/account-resetpassword?";
    return this.getWebBaseUrl() + "/account-resetpassword?";
  }

  getUnsubscribeConfsURI(): string {
    //return this.getWebBaseUrl() + "/event-unsubscribe?";
    return this.getWebBaseUrl() + AppConstants.PortalSuffix + "/event-unsubscribe?";
  }

  getUnsubscribeNewsURI(): string {
    //return this.getWebBaseUrl() + AppConstants.PortalSuffix + "/news-unsubscribe?";
    return this.getWebBaseUrl() + "/news-unsubscribe?";
  }

  getWebBaseUrl(): string {
    return location.protocol + "//" + location.hostname + ":" + location.port;
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }

  //
  setEnv() {
    if (isPlatformBrowser(this.platformId)) {
      let objCP = localStorage.getItem('cp');
      if (objCP) this.portal = JSON.parse(objCP);
    }
  }

  storeCookie() {
    if (!this.isCookie()) {
      localStorage.setItem('cookie', 'cookie');
    }
    this.currentFeatureCodeObserver.subscribe((response) => {
      if (response.length > 0) {
        this.currentFeatureCode = response;
      }
    });
    if (this.currentFeatureCode) {
      this.cookieTracking(!this.currentFeatureCode ? "OTHER" : this.currentFeatureCode).subscribe({
        next: (x) => {
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
  }

  isCookie(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return !!localStorage.getItem('cookie');
    }
    return false;
  }

  isOneTrustCookie(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      let consentCookie = this._cookieService.get('OptanonConsent');
      return !!(consentCookie.includes('groups=1%3A1%2C2%3A1')); // consentCookie.includes('intType=6')
    }
    return false;
  }

  initListener() {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('reload', () => this.reset());
      window.addEventListener('mousemove', () => this.reset());
      window.addEventListener('keydown', () => this.reset());
      window.addEventListener('scroll', () => this.reset());
    }

  }

  stopListener() {
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('reload', () => this.reset());
      window.removeEventListener('mousemove', () => this.reset());
      window.removeEventListener('keydown', () => this.reset());
      window.removeEventListener('scroll', () => this.reset());
    }
  }

  reset() {
    this.setLastAction(Date.now());
  }

  public getLastAction() {
    let dateString: any = localStorage.getItem("lastAction");
    return parseInt(dateString);
  }

  public setLastAction(lastAction: number) {
    localStorage.setItem("lastAction", lastAction.toString());
  }

  initInterval() {
    this.intervalClear = setInterval(() => {
      this.check();
    }, 15000);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + AppConstants.AutoLogoutTimeout * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout) {
      this.clearLocalStorage();
      let IsSessionTimeOut = true;
      this.logoutResponse(IsSessionTimeOut).subscribe((response) => {
        console.log('logout-res: ', response);
        this.logout();
        this.loginStatus.next(false);
        this.stopRefreshTokenTimer();
        this.UserFullName.next("");
        this.utilityService.isProfileSelection = true;
        this.route.navigate(['/'])
          .then(() => {
            if (isPlatformBrowser(this.platformId)) {
              window.location.reload();
            }
          });
      });
    }
  }

  sessionIdleTimeout() {
    this.setProfileSelectionTime();
    this.initIdelInterval();
    this.initListener();
  }

  setProfileSelectionTime(): void {
    localStorage.setItem("lastIdleTime", (Date.now()).toString());
  }

  public getLastIdleAction() {
    let dateString: any = localStorage.getItem("lastIdleTime") ?? 0;
    return parseInt(dateString);
  }

  initIdelInterval() {
    this.idleIntervalClear = setInterval(() => {
      this.checkIdleTime();
    }, 15000);
  }

  checkIdleTime(isFromApp: boolean = false): void {

    if (isFromApp && (this.getLastIdleAction() == 0 || this.getLastIdleAction() == null)) {
      localStorage.clear();
      sessionStorage.clear();
      return;
    }

    const now = Date.now();
    const timeleft = this.getLastIdleAction() + AppConstants.SessionIdleTimeout * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout) {
      clearInterval(this.idleIntervalClear);
      this.stopListener();
      localStorage.clear();
      sessionStorage.clear();
      if (!isFromApp) {
        this.route.navigate(['/'])
          .then(() => {
            window.location.reload();
          });
      }
    }
  }

  setLocalStorage(x: UserLoginResult) {
    this.clearLocalStorage();
    this.utilityService.resetSessionExpired();
    localStorage.setItem("lastAction", Date.now().toString());
    this.initListener();
    this.initInterval();
    //const secret_key = 'xyz@123';
    //this.encrDecrService.set('secret_key', x);
    let appUserInfo: AppUserInfo = this.utilityService.getAppUserInfoObject(x);

    if (isPlatformBrowser(this.platformId)) {
      if (appUserInfo) {
        localStorage.setItem('cpappuserinfo', JSON.stringify(appUserInfo));
      }
    }

    localStorage.setItem('access_token', x.AccessToken ?? '');
    localStorage.setItem('refresh_token', x.RefreshToken ?? '');
    localStorage.setItem('pk_refresh_token', x.PK_RefreshToken ?? '');
    localStorage.setItem('login-event', 'login' + Math.random());
    localStorage.setItem('isLoggedIn', 'true');
    this.startRefreshTokenTimer();
  }

  clearLocalStorage() {
    this.stopListener();
    clearInterval(this.intervalClear);
    localStorage.removeItem('cpappuserinfo');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('pk_refresh_token');
    sessionStorage.removeItem('IS_HCP');
    sessionStorage.removeItem('RT_ID');
    sessionStorage.removeItem('NON_RT_ID');
    sessionStorage.removeItem('RT_NAME');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('profInfo');
    localStorage.removeItem('cp');
    localStorage.removeItem("lastAction");
    localStorage.setItem('logout-event', 'logout' + Math.random());
  }

  sessionExpired() {
    localStorage.setItem('timeout-event', 'timeout' + Math.random());
  }


  /**
* This Method checks for route navigating page privilege. If user has privilege then returns true else false.
* @param router
* @param state
*/
  CheckPrivilege(
    router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let returnValue: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree = false;

    if (!(Object.keys(this.user).length === 0 && this.user.constructor === Object)) {

      if (this.user.Errors && this.user.Errors.length > 0) {
        this.route.navigateByUrl('/unauthorized');
        returnValue = false;
      }
      else {
        if (this.user.IsSuperAdmin) {
          switch (router.routeConfig?.path) {
            case 'super-admin':
            case 'config':
            case 'support':
              returnValue = true;
              //console.log('route:' + router.routeConfig?.path);
              break;
            default:
              returnValue = false;
              this.clearLocalStorage();
              this.route.navigateByUrl('/unauthorized');
              break;
          }
        }
        else if (!this.user.IsSuperAdmin) {
          switch (router.routeConfig?.path) {
            case 'home':
              //console.log('route:' + router.routeConfig?.path);
              returnValue = true;
              break;
            case 'therapuetic-areas':
            case 'product-detail':
            case 'new-request':
            case 'submit-request':
            case 'info':
            case 'msl':
            case 'msldetails':
              //console.log('route:' + router.routeConfig?.path);
              returnValue = true;
              break;

            default:
              returnValue = false;
              this.clearLocalStorage();
              this.route.navigateByUrl('/unauthorized');
              break;
          }
        }

      }
    }
    else if (router.routeConfig?.path == 'home' || router.routeConfig?.path == '' || router.routeConfig?.path == '**') {
      this.clearLocalStorage();
      this.route.navigateByUrl('/home');

    }
    else {
      this.routeToPreviousState(router.routeConfig?.path);
      return true;
    }

    return returnValue;
  }

  routeToPreviousState(path: string | undefined) {

    this.route.navigateByUrl(path ?? '');
    //this.userInfo$.subscribe(next => {
    //  this.user = next;
    //  this.route.navigateByUrl(path);
    //});
  }

  public startRefreshTokenTimer() {
    let token = localStorage.getItem("access_token");
    //let decTocken = this.aesService.decrypt(token);

    let decTocken = token;

    // parse json object from base64 encoded jwt token
    if (decTocken != null) {
      if (isPlatformBrowser(this.platformId)) {
        const jwtToken = JSON.parse(atob(decTocken.split(".")[1]));
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - 60 * 1000;
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
      }
    }
  }

  refreshToken() {
    const accessToken = localStorage.getItem('access_token') ?? '';
    const lsItem: any = localStorage.getItem('cpappuserinfo');
    const user = JSON.parse(lsItem);
    let jwtAuthResult: JwtAuthResult = {};
    if (user) {
      jwtAuthResult = {
        AccessToken: accessToken,
        RefreshToken: {
          UserName: user.UserID ?? '',
          ExpiredAt: new Date(),

        }
      };
    }
    else {
      jwtAuthResult = {
        AccessToken: accessToken,
        RefreshToken: {
          UserName: '',
          ExpiredAt: new Date(),

        }
      }
    }

    return this.http.post<any>(AppConstants.PortalApiBaseUrl + '/api/Auth/RefreshToken', jwtAuthResult)
      .pipe(
        map((jwt) => {
          this.setJwtRefreshToken(jwt);
          return jwt;
        }));
  }

  setJwtRefreshToken(jwt: JwtAuthResult) {
    if (jwt && jwt.Success) {
      localStorage.setItem('access_token', jwt.AccessToken ?? '');
      //localStorage.setItem('refresh_token', jwt.RefreshToken?.TokenString ?? '');
      //localStorage.setItem('pk_refresh_token', jwt.RefreshToken?.TokenID ?? '');
      this.startRefreshTokenTimer();
    }
    else {
      this.logout();
      this.route.navigate(['/'])
        .then(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.location.reload();
          }

        });
    }
  }

  public stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  public setTokenTimer(): void {
    let token = localStorage.getItem("access_token");
    //let decTocken = this.aesService.decrypt(token);

    let decTocken = token;

    if (decTocken && decTocken != "") {
      const jwtToken = JSON.parse(atob(decTocken.split(".")[1]));
      const date = new Date().toUTCString();
      const utcDate = new Date(date);
      let utcNowTime = utcDate.getTime();
      if (this.isUserLoggedIn() && (this.refreshTokenTimeout == undefined || this.refreshTokenTimeout == null)) {
        if ((jwtToken.exp * 1000) > utcNowTime) {
          this.refreshToken().subscribe();
        }
      }
    }
    else {
      this.logout();
    }
  }


  setCurrentFeatureCode(currentFeatureCode: any) {
    this.currentFeatureCodeSubject.next(currentFeatureCode);
  }

  public get selectCurrentFeature(): any {
    return this.currentFeatureCodeSubject.value;
  }

  getSignalRconnection() {
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(AppConstants.PortalApiBaseUrl + '/cphub')
      .build();
    return connection;
  }

  LogoutUserInOtherBrowser(): Observable<any> {
    //let browserName = this.detectBrowserName();
    //userData.PAGE_TITLE = this.titleService.getTitle();
    //userData.BROWSER_LANGUAGE = navigator.language;
    //userData.USER_AGENT = browserName;
    //userData.TIME_STAMP = new Date();
    //userData.EnvironmentID = AppConstants.EnvironmentId;
    return this.http.get<any>(AppConstants.PortalApiBaseUrl + '/api/Auth/LogoutUserInOtherBrowser/');
  }

}

export interface JwtAuthResult {
  AccessToken?: string
  RefreshToken?: RefreshToken;
  Success?: boolean;
  Errors?: string[];
  PK_USERID?: string;
}

export interface RefreshToken {
  UserName?: string;
  TokenID?: string;
  TokenString?: string;
  ExpiredAt?: Date;
}

// Shift + Alt + F
