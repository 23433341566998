<!--========= header start ==========-->
<header>
  <div class="topof-Header">
    <div class="container-fluid">
      <a routerLink="/" id="logo"><img src="./assets/images/logo.png" alt=""> </a>
      <ul>
        <li><a *ngIf="enabledMenuItems.isResources" routerLink="resources">RESOURCES</a></li>
        <li><a href="javascript:void(0)" (click)="openContactModal(template)">CONTACT US</a></li>
      </ul>
    </div>
  </div>
  <div class="mainNav">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <nav class="navbar navbar-expand-lg ">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
          </button>
          <a routerLink="/" id="logo-mbl"><img src="./assets/images/mbl-logo.png" alt=""> </a>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item header-tab-item" *ngIf="enabledMenuItems.isSMI || enabledMenuItems.isAE" routerLinkActive="active"><a class="nav-link" routerLink="home"><i class="fas fa-home"></i></a></li>
              <li class="nav-item header-tab-item" *ngIf="enabledMenuItems.isSMI" routerLinkActive="active"><a class="nav-link" routerLink="find-medical-info">Search Medical Information</a></li>
              <li class="nav-item header-tab-item" *ngIf="enabledMenuItems.isRMI" routerLinkActive="active"><a class="nav-link" routerLink="request-mi">Request for Medical Information</a></li>
              <li class="nav-item header-tab-item" *ngIf="enabledMenuItems.isMSL" routerLinkActive="active"><a class="nav-link" routerLink="find-msl">Find My MSL</a></li>
              <li class="nav-item header-tab-item" *ngIf="enabledMenuItems.isAE" routerLinkActive="active"><a class="nav-link" routerLink="report-ae">Report an Adverse Event</a></li>
              <li class="nav-item header-tab-item" *ngIf="enabledMenuItems.isPC" routerLinkActive="active"><a class="nav-link" routerLink="report-pc">Report a Product Complaint</a></li>             
              <li class="nav-item header-tab-item" *ngIf="enabledMenuItems.isNews" routerLinkActive="active"><a class="nav-link" routerLink="news-announcements">News and Announcements</a></li>
              <li class="nav-item header-tab-item" *ngIf="enabledMenuItems.isConferences" routerLinkActive="active"><a class="nav-link" routerLink="events-conferences">Events and Conferences</a></li>
              <li class="nav-item header-tab-item" *ngIf="enabledMenuItems.isProducts" routerLinkActive="active"><a class="nav-link" routerLink="our-products">Our Products</a></li>
            </ul>
          </div>
        </nav>

       <ng-container *ngIf="enableLoginItems"> 
        <div class="dropdown useraccount" dropdown>
          <button *ngIf="!(LoginStatus$ | async) as LoginStatus" (click)="login()"
                  class="btn btn-secondary fs-14" type="button" id="dropdownMenuButton">
            <i *ngIf="LoginStatus" class="fas fa-user" title="Login"></i>&nbsp;Login
          </button>
          <span *ngIf="!(LoginStatus$ | async) as LoginStatus" class="text-white">&nbsp;|&nbsp;</span>
          <button (click)="register()" *ngIf="!(LoginStatus$ | async) as LoginStatus"
                  class="btn btn-secondary fs-14" type="button" id="dropdownMenuButton">
            <i class="" *ngIf="LoginStatus" title="Register"></i>Register
          </button>
          <button *ngIf="(LoginStatus$ | async) as LoginStatus" class="btn btn-secondary dropdown-toggle fs-14" type="button" id="dropdownMenuButton" dropdownToggle> 
             <span *ngIf="UserFullName$ | async as UserFullName"> <i class="fas fa-user"></i> &nbsp;&nbsp;{{(UserFullName.length>8)? (UserFullName | slice:0:9)+'...':(UserFullName)}}</span>
           </button>         
          <div *dropdownMenu class="dropdown-menu dropdown-menu-ovrd">
            <a class="dropdown-item" href="javascript:void(0)" routerLink="my-profile">My Profile</a>
            <a class="dropdown-item" href="javascript:void(0)" routerLink="my-requests">My Requests</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
          </div>
        </div>
         </ng-container> 
      </div>

    </div>
  </div>
</header>
<!--========= header end ==========-->

<ng-template #template>
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block">Contact Us
      <span class="mt-1 float-right fs-13" data-dismiss="modal" (click)="modalRef.hide()">
        <i class="fas fa-times"></i></span>
    </h5>
  </div>
  <div class="modal-body px-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 col-md-12">
          <ul class="contactUs">
            <li>
              <div class="row">
                <div class="col-md-12">
                  <span class="text-center"><i class="fas fa-phone-alt"></i></span>
                  <small>000-000-0000 (000-00-ALPHA) available on Monday – Friday from 9am - 7pm.</small>
                </div>
                <div class="col-md-12">
                  <span class="text-center"><i class="fas fa-envelope"></i></span>
                  <a href="mailto:medinfo@alpharma.com?subject=Medical%20Information%20Request" class="d-block fs-13">medinfo@alphapharma.com</a>
                </div>
              </div>
            </li>
  
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
