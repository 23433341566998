
 <div class="container-fluid p-0">
  <div class="loginBanner position-reative">
      <div class="loginHeading text-center">Easy Access to Improve Your Medical Practice</div>
      <div class="bottomof-bannercontent w-100 px-3 py-2 text-center">
          <p class="text-white">COVID-19 is an emerging, rapidly evolving situation. Get the latest public health information from CDC <a href="https://www.coronavirus.gov."> : https://www.coronavirus.gov.</a>
            Get the latest research information from NIH: <a href="https://www.nih.gov/coronavirus">https://www.nih.gov/coronavirus</a> </p>
        </div>

      <div class="bannercontent">
        <div class="container-fluid">
              <div class="row">
                  <div class="col-md-8 col-sm-8"></div>
                  <div class="col-md-4 col-sm-4 pr-0">
                    <div class="bannercontent-block">
                    <h2>Alpha Pharma HCP Portal</h2>
                    <p class="text-white">
                      This portal is intended for US healthcare professionals only.Alpha Pharma HCP Portal is a platform for HCPs to access scientific content, submit requests, discover our therapeutic areas and gain access to various programs offered by us.</p>
                  </div>
                  </div>
              </div>
        </div>
    </div>

  </div>
</div>
<div class="privacyPrompt">
  <div class="row" *ngIf=showCookiesPopUp>
      <div class="col-md-7">
         <p>We will place necessary cookies for our website to operate , unless you also allow any other cookies from this panel. for more information please click on "Settings"
          </p>
      </div>
      <div class="col-md-5">
            <div class="text-right w-100 pull-left cookieSettings">
              <a (click)="cookieSettings()" id="allow-selection" class="cursor-pointer">Allow Selection</a> 
              <a (click)="allowCookies()" id="allow-cookies" class="cursor-pointer ml-2">Allow all Cookies</a>
          </div>
      </div>
  </div>
  <div class="ckSelections w-100">
  <div class="row" *ngIf=showSelectionItems>
    <div class="col-md-4 col-sm-6">
        <div class="custom-control custom-checkbox ">
          <input type="checkbox" class="custom-control-input" id="exampleCheck1" name="example1">
          <label class="custom-control-label" for="exampleCheck1">Strictly necessary cookies</label>
        </div>
    </div>
    <div class="col-md-4 col-sm-6">
      <div class="custom-control custom-checkbox ">
        <input type="checkbox" class="custom-control-input" id="exampleCheck2" name="example1">
        <label class="custom-control-label" for="exampleCheck2">Functional cookies</label>
      </div>
   </div>
   <div class="col-md-4 col-sm-6">
    <div class="custom-control custom-checkbox ">
      <input type="checkbox" class="custom-control-input" id="exampleCheck3" name="example1">
      <label class="custom-control-label" for="exampleCheck3">Performance</label>
    </div>
   </div>
   <div class="col-md-4 col-sm-6">
    <div class="custom-control custom-checkbox ">
      <input type="checkbox" class="custom-control-input" id="exampleCheck4" name="example1">
      <label class="custom-control-label" for="exampleCheck4">Social Media Cookies</label>
    </div>
   </div>
    <div class="col-md-4">
      <p>more information please view our <a href="https://www.techsolcorp.com/cookie-policy/" target="_blank" class="fs13">Privacy Policy.</a></p>
    </div>
 </div>
</div>
      
</div> 


