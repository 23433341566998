import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { map, Observable, tap } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppConstants } from '../../../shared/AppConstants';
import { PortalProfiles } from '../../_models/portals';
import { HomeService } from '../../_services/home.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  LoginStatus$: Observable<boolean>;
  isUS_HCP: boolean = true;
  copyrightCurrentYear: number = new Date().getFullYear();
  featuresList: any;
  enabledFooterItems: any;
  profilename?: string;

  constructor(public authService: AuthenticationService,
    public utilityService: UtilityService,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private homeService: HomeService) {


  }

  ngOnInit(): void {
    setTimeout(() => {
      this.LoginStatus$ = this.authService.isLoggedIn;
    }, 100);

    this.enabledFooterItems = AppConstants.enabledMenuItems;
    this.homeService.featureProfileObserver.subscribe((response) => {
      this.featuresList = response;
      this.enabledFooterItems = [];
      if (this.featuresList && this.featuresList.length > 0) {
        for (let index = 0; index < this.featuresList.length; index++) {
          const component = this.featuresList[index];
          switch (component.Code) {
            case 'RESO':
              this.enabledFooterItems.isResources = component.IS_ACTIVE;
              break;
            case 'ST_MP':
              this.enabledFooterItems.isSitemap = component.IS_ACTIVE;
              break;
          }
        }
      }
    });
    this.homeService.selectedProfileObserver.subscribe((response) => {
      if (response) {
        this.profilename = response.NAME;
      }
    });
  }

  onActionNonHCP() {
    this.utilityService.isProfileSelected();
  }

  openContactModal(template: TemplateRef<any>) {
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(template, initialState);
  }

  OnNotProfileClick() {
    this.utilityService.isProfileSelection = true;
    this.utilityService.actionOnSelfQualification(false);
    localStorage.removeItem('profInfo');
  }

  openCookie(): void {
    let oneTrustCookieBanner = document.getElementById('onetrust-pc-btn-handler');
    if (oneTrustCookieBanner) {
      oneTrustCookieBanner.click();
      return;
    }
    let oneTrustCookie = document.getElementById('onetrust-pc-sdk');
    if (oneTrustCookie) {
      oneTrustCookie.removeAttribute('style');
      oneTrustCookie.classList.remove('ot-hide');
      oneTrustCookie.style.height = '100%';
    }
  }

}
