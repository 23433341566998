import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, makeStateKey, OnInit, PLATFORM_ID, TransferState } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { AppConstants } from '../shared/AppConstants';
import { LoginComponent } from './components/login/login.component';
import { ACCESSTYPES, LOGOUT_REASON } from './_enums/constant-types.enum';
import { WindowRef } from './_helpers/window-handles.service';
import { PortalProfiles, SearchComponents } from './_models/portals';
import { AppUserInfo, UserLoginResult, UserProfiles } from './_models/user';
import { SeoService } from './_resolver/seo.servece';
import { TransferStateService } from './_resolver/seo.statetransfer.service';
import { AlertifyService } from './_services/alertify.service';
import { AuthenticationService } from './_services/authentication.service';
import { HomeService } from './_services/home.service';
import { UtilityService } from './_services/utility.service';
import packageJson from '../../package.json';
import { AppPreSetupService } from './_services/app-pre-setup.service';
const Tags_Data_Key = makeStateKey<any>('tags_data');


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @BlockUI() blockUI: NgBlockUI | undefined;

  //idle properties
  timed: boolean = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing: Date | null = null;
  countdown: any;
  displayCountdown: any;

  isIdleTimeout: boolean = false;
  sessionExpired = new Subject();
  IsSessionExpired: boolean = false;
  public version: string = '';
  public onClose: Subject<boolean>;
  isLoggedInUser: boolean = false;
  sessionID: string = '';

  LoginStatus$: Observable<boolean>;
  HomeUrlPath: string = '';

  showHeader: boolean = false;
  showFooter: boolean = false;
  cookieToogle: boolean = true;
  cookieSelectionToogle: boolean = false;
  subscribeGetPortal: Subscription | null = null;
  private subRouteQueryParams: Subscription | null = null;
  userProfilesList: Array<UserProfiles>;
  regLogin: boolean = false;
  userAuthenticationType: string | null;
  portalProfiles?: PortalProfiles[];
  selectedList: any;
  selectedFromPopup: boolean = false;

  platformId: any;
  url?: string;
  CurrentTags: any;
  userDetailsJson: any;


  TagsData$: any;

  CurrentTags$: any;
  allTags$: any;
  _page: any;
  actionPerformed: boolean = false;
  constructor(public utilityService: UtilityService,
    public authService: AuthenticationService,
    private homeService: HomeService,
    private titleService: Title, private metaTagService: Meta,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    public router: Router,
    private activateRoute: ActivatedRoute,
    private alertify: AlertifyService,
    private idle: Idle,
    private transferState: TransferState,
    private seoService: SeoService, private ts: TransferState,
    private ap: AppPreSetupService,
    @Inject(PLATFORM_ID) platformId: any) {
    this.platformId = platformId;
    this.onClose = new Subject();
    this.setSEOTags();
    this.version = packageJson.version;

    /**
     * on route change to '/account-verify' and /account-resetpassword, set the variable showHeader, showFooter to false
     */
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'].indexOf('/account-verify') <= -1
          && event['url'].indexOf('/account-resetpassword') <= -1
          && event['url'].indexOf('/event-unsubscribe') <= -1
          && event['url'].indexOf('/news-unsubscribe') <= -1) {
          this.showHeader = true;
          this.showFooter = true;
        } else {
          this.showHeader = false;
          this.showFooter = false;
        }
        this.HomeUrlPath = event.url;
      }
    });

    //idle.setIdle(10);// sets an idle timeout of x time
    //idle.setTimeout(AppConstants.SessionInactiveTime); // sets a timeout period of x time. after 10 seconds of inactivity, the user will be considered timed out.
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document

    idle.setIdle(AppConstants.SessionIdleTimeout * 60);
    idle.setTimeout(1);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
    });

    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.displayCountdown = this.secondsToTime(countdown);
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      this.isIdleTimeout = true;
      //console.log(countdown)
      if (countdown == 1) {
        this.timed = true;
        //console.log("I am happy");
        if (authService.isUserLoggedIn()) {
          authService.logout();
          authService.sessionExpired();
          this.router.navigate(['/'], { queryParams: { referrer: 'SESSION_EXPIRED' } })
            .then(() => {
              this.utilityService.setSessionExpired();
              if (isPlatformBrowser(this.platformId)) {
                window.location.reload();
              }
            });
        }
      }
    });

    this.resetTimeout();

  }

  closeTimeoutPopup() {
    this.IsSessionExpired = false;
    this.resetTimeout();
    this.router.navigate(['/']);
  }

  resetTimeout() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.sessionID = '';
    this.timedOut = false;
    this.isIdleTimeout = false;
    this.IsSessionExpired = false;
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((e: NavigationEnd) => this.url = e.urlAfterRedirects)
    ).subscribe(pageurl => {
    });

    this.refreshOnBackButtonClick();
    //#region CHECK FOR MULTI SESSION LOGGIN
    if (isPlatformBrowser(this.platformId)) {
      const connection = this.authService.getSignalRconnection();
      connection.start().then(function () {
        console.trace('CP Hub Connected!');
      }).catch(function (err) {
        console.log(err.toString());
        return console.error(err.toString());
      });
      if (connection) {
        connection.on("MultiBrowserLogInMessage", (resp) => {
          let loggedIntest = this.authService.isUserLoggedIn();//checking is user logged in
          if (loggedIntest) {
            this.userDetailsJson = localStorage.getItem('cpappuserinfo');
            if (this.userDetailsJson) {
              let userId = JSON.parse(this.userDetailsJson).PK_UserId;
              if (resp.data.userId == userId) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.setItem('lr', 'Session Override');
                this.authService.multiLogout(LOGOUT_REASON.SESSION_OVERRIDE);
                this.alertify.ConfirmationBanner(resp.data.name, this.navigateToHome(), "Ok", "", false);
              }
            }
          }
        });
      }
    }
    //#endregion CHECK FOR MULTI SESSION LOGGIN

    this.portalProfiles = AppConstants.PortalProfiles;//Get portal profiles to local variable.
    //this.GetProfiles();
    if (this.portalProfiles && this.portalProfiles.length == 1 && this.portalProfiles[0].ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED
      && !this.portalProfiles[0].IS_LOGIN_OPTIONAL) {
      this.showHeader = false;
      this.utilityService.isProfileSelection = false;
      this.utilityService.actionOnSelfQualification(true);
      this.homeService.setselectedProfile(this.portalProfiles[0]);
      // this.getUserProfileFeatures(this.portalProfiles[0].PK_ENV_PROFILE_ID??'');
      //this.setFeaturesList(this.portalProfiles[0]);
      if (isPlatformBrowser(this.platformId)) {
        localStorage.removeItem('profInfo');
        localStorage.setItem('profInfo', JSON.stringify(this.portalProfiles[0]));
      }
      let loggedIn = this.authService.isUserLoggedIn();//checking is user logged in
      if (!loggedIn) {
        this.GenerateGuestToken(this.portalProfiles[0].PK_ENV_PROFILE_ID ?? '');
        this.loginPopup();
        return;
      }
    }
    let loggedIn = this.authService.isUserLoggedIn();//checking is user logged in
    if (loggedIn) {
      this.authService.check();//checking session timeout
      this.authService.setEnv();//adding portal data to portal variable in utility service
      this.utilityService.setEnv();// setting appconstants reportertype id ,hcp,non hcp rt names
      let userdata: AppUserInfo = this.utilityService.getAppUserInfo();
      if (userdata && userdata.ACCESS_TYPE_CODE && userdata.FK_ENV_PROF_ID) {
        let profiledata: PortalProfiles = {
          ACCESS_TYPE_CODE: userdata.ACCESS_TYPE_CODE,
          PK_ENV_PROFILE_ID: userdata.FK_ENV_PROF_ID,
          NAME: userdata.ENV_PROFILE,
          PROF_TYPE_CODE: userdata.PROF_TYPE_CODE,
          IS_LOGIN_OPTIONAL: userdata.IS_LOGIN_OPTIONAL,
        }
        this.NavigatePortalUser(profiledata);
      }
      else {
        localStorage.setItem('isLoggedIn', 'false');
        let profileinfo: PortalProfiles = {};
        profileinfo = this.utilityService.getSelectedProfile();
        if (profileinfo.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED) {
          this.showHeader = false;
          this.utilityService.isProfileSelection = false;
          this.utilityService.actionOnSelfQualification(true);
          this.homeService.setselectedProfile(profileinfo);
          //this.setFeaturesList(profileinfo);
          if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('profInfo');
            localStorage.setItem('profInfo', JSON.stringify(profileinfo));
          }
          this.loginPopup();
        }
        else if (profileinfo.ACCESS_TYPE_CODE == ACCESSTYPES.NO_ACCESS) {
          this.actionOnIamNonHCP();
        }
      }
    }
    if (!loggedIn) {
      let profileinfo: PortalProfiles = {};
      profileinfo = this.utilityService.getSelectedProfile();
      if (profileinfo.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED) {
        this.showHeader = false;
        this.utilityService.isProfileSelection = false;
        this.utilityService.actionOnSelfQualification(true);
        this.homeService.setselectedProfile(profileinfo);
        //this.setFeaturesList(profileinfo);
        if (isPlatformBrowser(this.platformId)) {
          localStorage.removeItem('profInfo');
          localStorage.setItem('profInfo', JSON.stringify(profileinfo));
        }
        this.loginPopup();
      }
      else if (profileinfo.ACCESS_TYPE_CODE == ACCESSTYPES.NO_ACCESS) {
        this.actionOnIamNonHCP();
      }
    }
    this.IsSessionExpired = false;
    this.resetTimeout();
    this.cookieToogle = !this.authService.isCookie();
    if (this.utilityService.isProfileSelected() || !this.utilityService.isProfileSelection) {
      this.utilityService.isProfileSelection = false;
      this.utilityService.actionOnSelfQualification(true);
      if (isPlatformBrowser(this.platformId)) {
        let profileinfo: PortalProfiles = {};
        profileinfo = this.utilityService.getSelectedProfile();
        if (profileinfo.PK_ENV_PROFILE_ID) {
          this.homeService.setselectedProfile(profileinfo);
          let tkn = localStorage.getItem('access_token')
          if (tkn) {
            this.getUserProfileFeatures(profileinfo.PK_ENV_PROFILE_ID)
          }
          else {
            //this.NavigatePortalUser(profileinfo);
            let featureList: any;
            this.homeService.featureProfileObserver.subscribe((response) => {
              featureList = response;
              if (featureList && featureList.length > 0) {
                this.setFeaturesList(featureList)
              }
            });
          }
        }
        else {
          this.utilityService.isProfileSelection = true;
          this.utilityService.actionOnSelfQualification(false);
        }
      }
      return;
    }
    this.LoginStatus$ = this.authService.isLoggedIn;
    this.subRouteQueryParams = this.activateRoute.queryParams.subscribe({
      next:
        params => {
          let referrer = params['referrer'];
          this.sessionID = referrer != null && referrer != "" ? referrer : null;
          if (this.sessionID == 'SESSION_EXPIRED') {
            if (!this.authService.isUserLoggedIn()) {
              this.IsSessionExpired = true;
            }
            else {
              this.router.navigate(['/']);
            }
          }
          else {
            this.IsSessionExpired = false;
          }
        }, error: (err) => {

          this.alertify.errorBaner("Error occured while navigating to the requested resource. Please check detailed error:\n\n" + err);
        }
    });
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('storage', (event) => {
        if (event.key == 'logout-event') {
          this.router.navigate(['/'])
            .then(() => {
              window.location.reload();
            });
        }
      }, false);
    }
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('storage', (event) => {
        if (event.key == 'timeout-event') {
          this.utilityService.setSessionExpired();
          this.router.navigate(['/'], { queryParams: { referrer: 'SESSION_EXPIRED' } })
            .then(() => {
              this.utilityService.setSessionExpired();
              window.location.reload();
            });
        }
      }, false);
    }
  }

  refreshOnBackButtonClick(): void {
    this.router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        if (isPlatformBrowser(this.platformId)) {
          window.location.reload();
        }

      }
    });
  }

  GetProfiles() {
    this.homeService.getUserProfiles().subscribe({
      next: (data) => {
        if (data) {
          this.portalProfiles = data;
          AppConstants.PortalProfiles = data;
        }
      },
      error: err => {
        console.log(err);
      }
    })
  }

  secondsToTime(d: any) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? (h.toString().length == 1 ? '0' + h : h) : "00";
    var mDisplay = m > 0 ? (m.toString().length == 1 ? '0' + m : m) : "00";
    var sDisplay = s > 0 ? (s.toString().length == 1 ? '0' + s : s) : "00";
    return hDisplay + ":" + mDisplay + ":" + sDisplay;
  }

  close() {
    this.onClose.next(false);
    this.modalRef.hide();
  }

  openLoginForm() {
    this.close();
    this.IsSessionExpired = false;
    this.router.navigate(['/']);
    this.loginPopup();
  }

  loginPopup() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(LoginComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.onLogin.subscribe((result: UserLoginResult) => {
      if (result != null) {
        this.authService.loginStatus.next(true);
        this.authService.UserFullName.next(result.UserFullName ?? "");
        this.authService.hcpDetails.next(this.utilityService.getAppUserInfoObject(result));
        this.isLoggedInUser = this.authService.isUserLoggedIn();
        //this.homeService.selectedProfileObserver.subscribe((response) => {
        //  this.selectedList = response;
        //  if (this.selectedList && this.selectedList.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED && !this.selectedList.IS_LOGIN_OPTIONAL) {
        //    this.close();
        //  }
        //});
      }
    });
  }

  getPortal() {
    this.subscribeGetPortal = this.homeService.getPortal().subscribe({
      next: (data) => {
        this.authService.portal = data;
        AppConstants.PortalProfiles = data.PortalProfiles;
        this.portalProfiles = data.PortalProfiles;
        this.utilityService.portalTitle = data.PortalTitle;
        //if (!this.utilityService.isProfileSelected()) {
        //  this.utilityService.isProfileSelection = true;
        //}
        this.utilityService.setEnvironmentStorage(data);
        this.blockUI?.stop();
      },
      error: (err) => {
        this.blockUI?.stop();
        console.error(err);
      }
    });
  }

  actionOnIamHCP() {
    this.utilityService.actionOnSelfQualification(true);
  }

  actionOnIamNonHCP() {
    this.utilityService.isProfileSelection = true;
    this.utilityService.isNonHCP = true;
  }

  NavigatePortalUser(userProfile: PortalProfiles, selectedFromPopup: boolean = false) {
    this.selectedFromPopup = selectedFromPopup;
    this.utilityService.actionOnSelfQualification(true);
    AppConstants.ReporterTypeId = userProfile.PK_ENV_PROFILE_ID;
    AppConstants.ReporterTypeName = userProfile.NAME;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('RT_ID', userProfile.PK_ENV_PROFILE_ID ?? '');
      this.homeService.setselectedProfile(userProfile);
      if (userProfile) {
        localStorage.removeItem('profInfo');
        localStorage.setItem('profInfo', JSON.stringify(userProfile));
      }
    }


    if (userProfile.ACCESS_TYPE_CODE == ACCESSTYPES.NO_ACCESS) {
      this.actionOnIamNonHCP();
    }
    else if (userProfile.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED && !this.authService.isUserLoggedIn()) {
      this.close();
      this.GenerateGuestToken(userProfile.PK_ENV_PROFILE_ID ?? '');
      this.loginPopup();
    }
    else if (userProfile.ACCESS_TYPE_CODE == ACCESSTYPES.GUEST) {
      this.GenerateGuestToken(userProfile.PK_ENV_PROFILE_ID ?? '');
    }

    this.authService.sessionIdleTimeout();
  }

  getUserProfileFeatures(userProfileId: string) {
    let data: SearchComponents = {
      ProfileId: userProfileId
    }
    this.homeService.getUserProfileFeatures(data).subscribe({
      next: (data) => {
        if (data) {
          this.setFeaturesList(data)
        } else {
          console.log("No User Profile Data")
        }
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  setFeaturesList(profileFeature: any): void {
    this.homeService.setUserProfileFeaturesList(profileFeature);
    this.homeService.setFeatures(this.selectedFromPopup);
    this.selectedFromPopup = false;
  }

  onBack() {
    this.utilityService.isNonHCP = false;
    this.utilityService.isProfileSelection = true;
  }

  onNonHCPClose() {
    this.utilityService.IsNotHcpAction = false;
  }


  allowCookies() {
    this.cookieToogle = false;
    this.cookieSelectionToogle = false;

    if (!this.authService.isCookie()) {
      this.authService.storeCookie();
    }
  }

  closeCookies() {
    this.cookieToogle = false;
  }

  cookieSelection() {
    this.cookieSelectionToogle = true;
  }


  @HostListener('window:unload')
  async ngOnDestroy() {
    if (!this.authService.isUserLoggedIn()) {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.removeItem('UserAuthenticationType');
        sessionStorage.removeItem('IS_HCP');
      }

    }
  }

  GenerateGuestToken(profileId: string) {
    this.homeService.generateGuestToken().subscribe({
      next: (data) => {
        if (data) {
          console.log("Guest token generated successfully.")
          this.getUserProfileFeatures(profileId);
        } else {
          console.log("Guest token generation failed.")
        }
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  setSEOTags() {
    let title = "";
    (this.CurrentTags == undefined || (this.CurrentTags?.Title == "")) ? title = this.utilityService.getPortalName() : title = this.CurrentTags.Title;
    this.titleService.setTitle(title);

    this.metaTagService.addTags([
      { name: "description", content: '' },
      { name: "title", content: '' },
      { name: 'robots', content: 'index, follow' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ]);
  }


  //setSEOTags() {

  //  let pageCode: string = '';
  //  let _pageUrl: string = '';
  //  let defaultTitle: string = '';
  //  let hasgetUrl: boolean = false;

  //  this.ap.seoTagsData$.subscribe((response: any) => {
  //    if (response) {
  //      this.allTags$ = response;
  //    }
  //  });

  //  let test: any = this.allTags$;

  //  //this.allTags$ = test.collection('tags_data', (ref: any) => ref.where(ref.FeatureCode == 'F_MSL'))
  //  //  .valueChanges({
  //  //    idField: 'FeatureCode'
  //  //  })
  //  //  .pipe(
  //  //    startWith(this.ts.get(Tags_Data_Key, []))
  //  //  );

  //  this.allTags$ = this.ap.seoTagsData$.pipe(
  //    startWith(this.ts.get(Tags_Data_Key, [])),
  //  );



  //  this.allTags$
  //    .pipe(
  //      tap((item: any) => {
  //        this.ts.set(Tags_Data_Key, item);
  //      }),
  //      map((item: any) => {

  //        if (this._page == '/home') {
  //          pageCode = 'HOME';
  //          defaultTitle = this.utilityService.getPortalName();
  //          return item.filter((x: any) => { return x.FeatureCode == pageCode })[0];
  //        }

  //        if (this._page == '/find-medical-info') {
  //          pageCode = 'SR_MI';
  //          defaultTitle = 'Search Medical Information';
  //          return item.filter((x: any) => { return x.FeatureCode == pageCode })[0];
  //        }

  //        if (this._page == '/request-mi') {
  //          pageCode = 'RQ_MI';
  //          defaultTitle = 'Request for Medical Information';
  //          return item.filter((x: any) => { return x.FeatureCode == pageCode })[0];
  //        }

  //        if (this._page == '/find-msl') {
  //          pageCode = 'F_MSL';
  //          defaultTitle = 'Find My MSL';
  //          return item.filter((x: any) => { return x.FeatureCode == pageCode })[0];
  //        }

  //        if (this._page == '/report-ae') {
  //          pageCode = 'RQ_AE';
  //          defaultTitle = 'Report an Adverse Event';
  //          return item.filter((x: any) => { return x.FeatureCode == pageCode })[0];
  //        }

  //        if (this._page == '/report-pc') {
  //          pageCode = 'RQ_PC';
  //          defaultTitle = 'Report a Product Complaint';
  //          return item.filter((x: any) => { return x.FeatureCode == pageCode })[0];
  //        }


  //        if (this._page == '/news-announcements') {
  //          pageCode = 'NS_AN';
  //          defaultTitle = 'News and Announcements';
  //          return item.filter((x: any) => { return x.FeatureCode == pageCode })[0];
  //        }


  //        if (this._page == '/events-conferences') {
  //          pageCode = 'EV_CF';
  //          defaultTitle = 'Events and Conferences';
  //          return item.filter((x: any) => { return x.FeatureCode == pageCode })[0];
  //        }

  //        if (this._page == '/our-products') {
  //          pageCode = 'OR_PD';
  //          defaultTitle = 'Our Products';
  //          return item.filter((x: any) => { return x.FeatureCode == pageCode })[0];
  //        }

  //        if (this._page == '/resources') {
  //          pageCode = 'RESO';
  //          defaultTitle = 'Resources';
  //          return item.filter((x: any) => { return x.FeatureCode == pageCode })[0];
  //        }

  //        if (this._page == '/sitemap') {
  //          pageCode = 'ST_MP';
  //          defaultTitle = 'Site Map';
  //          return item.filter((x: any) => { return x.FeatureCode == pageCode })[0];
  //        }

  //        //return item.filter((x: any) => { return x.FeatureCode == 'F_MSL' })[0];
  //      }
  //      ),
  //    )
  //    .subscribe((tags: any) => {

  //      this.metaTagService.addTags([
  //        { name: "description", content: '' },
  //        { name: "title", content: '' },
  //        { name: 'robots', content: 'index, follow' },
  //        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  //      ]);

  //      debugger;
  //      if (tags) {

  //        this.CurrentTags$ = tags
  //        let title = "";
  //        pageCode != 'HOME' ? ((tags == undefined || (tags?.Title == "")) ? title = defaultTitle : title = tags.Title) : defaultTitle;
  //        this.titleService.setTitle(title);
  //        if (tags != null && tags != undefined) {
  //          this.metaTagService.updateTag({ name: 'description', content: tags.MetaDescription });
  //          this.metaTagService.updateTag({ name: 'title', content: tags.MetaTitle });
  //        }
  //        else {
  //          this.metaTagService.updateTag({ name: 'title', content: "" });
  //          this.metaTagService.updateTag({ name: 'description', content: "" });
  //        }
  //      }
  //      else {
  //        this.metaTagService.updateTag({ name: 'title', content: "" });
  //        this.metaTagService.updateTag({ name: 'description', content: "" });
  //      }

  //    });



  //}


  //TagsData: any;
  //setSEOTags(feature: string) {
  //  let title = "";


  //  let featureCode: string = '';

  //  if (feature = '/report-ae') {
  //    featureCode = 'RQ_AE';
  //    this.titleService.setTitle("TESTING AE");

  //    this.metaTagService.addTag({ name: 'title', content: 'updated test' });
  //    this.metaTagService.addTag({ name: 'description', content: 'updated test' });



  //    //(this.CurrentTags == undefined || (this.CurrentTags?.Title == "")) ? title = this.utilityService.getPortalName() : title = this.CurrentTags.Title;
  //    //this.titleService.setTitle(title);
  //    //this.metaTagService.addTags([
  //    //  { name: 'robots', content: 'index, follow' },
  //    //  { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  //    //]);

  //    //let input = { Feature: "", TagType: "", Tags: "", Status: null };
  //    //this.utilityService.GetFeaturesSummary(input).subscribe((list: any) => {
  //    //  this.TagsData = list
  //    //  if (this.TagsData && this.TagsData.length > 0) {

  //    //    //["/home", "/sitemap", "/request-mi", "/find-msl", "/find-medical-info", "/news-announcements", "/report-ae", "/report-pc", "/events-conferences", "/our-products", "/resources"]

  //    //    let featureCode: string = '';

  //    //    if (feature = '/report-ae') {
  //    //      featureCode = 'RQ_AE';
  //    //      this.titleService.setTitle("TESTING AE");

  //    //      this.metaTagService.addTags([
  //    //        { name: "description", content: "testing title" },
  //    //        { name: "title", content: 'description tetsing' },
  //    //        { name: 'robots', content: 'index, follow' },
  //    //        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  //    //      ]);

  //    //    }
  //    //    this.CurrentTags = this.getCurrentPageTags(featureCode);
  //    //    let title = "";
  //    //    (this.CurrentTags == undefined || (this.CurrentTags?.Title == "")) ? title = this.utilityService.getPortalName() : title = this.CurrentTags.Title;
  //    //    this.titleService.setTitle(title);

  //    //    if (this.CurrentTags != null && this.CurrentTags != undefined) {
  //    //      this.metaTagService.addTag({ name: 'title', content: this.CurrentTags.MetaTitle });
  //    //      this.metaTagService.addTag({ name: 'description', content: this.CurrentTags.MetaDescription });
  //    //    }
  //    //    else {
  //    //      this.metaTagService.addTag({ name: 'title', content: "" });
  //    //      this.metaTagService.addTag({ name: 'description', content: "" });
  //    //    }
  //    //  }
  //    //  else {
  //    //    this.metaTagService.addTag({ name: 'title', content: "" });
  //    //    this.metaTagService.addTag({ name: 'description', content: "" });
  //    //  }
  //    //});
  //  }
  //}

  getCurrentPageTags(Code: string) {
    let features = this.TagsData$;
    let tagData = features.filter((x: any) => { return x.FeatureCode == Code })[0];
    return tagData;
  }

  navigateToHome() {
    let url = this.homeService._setFeatures();
    this.router.navigate([url]);
  }



  ////#region Testing SEO SECTION
  //setSEOTags() {
  //  this.metaTagService.addTags([
  //    { name: "description", content: "" },
  //    { name: "title", content: '' },
  //    { name: 'robots', content: 'index, follow' },
  //    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  //  ]);

  //  this.ap.seoTagsData$.subscribe((response: any) => {
  //    if (response) {
  //      this.allTags$ = response;
  //    }
  //  });

  //  this.allTags$ = this.ap.seoTagsData$.pipe(
  //    startWith(this.ts.get(Tags_Data_Key, [])),
  //  );

  //  this.allTags$
  //    .pipe(
  //      tap((item: any) => {
  //        this.ts.set(Tags_Data_Key, item);
  //      }),
  //      map((item: any) => {
  //        return item.filter((x: any) => { return x.FeatureCode == 'RQ_AE' })[0];
  //      }
  //      ),
  //    )
  //    .subscribe((tags: any) => {
  //      this.CurrentTags$ = tags;
  //      let title = "";
  //      (tags == undefined || (tags?.Title == "")) ? title = "Report an Adverse Event" : title = tags.Title;
  //      this.titleService.setTitle(title);
  //      if (tags != null && tags != undefined) {
  //        this.metaTagService.updateTag({ name: 'description', content: tags.MetaDescription });
  //        this.metaTagService.updateTag({ name: 'title', content: tags.MetaTitle });
  //      }
  //      else {
  //        this.metaTagService.updateTag({ name: 'title', content: "" });
  //        this.metaTagService.updateTag({ name: 'description', content: "" });
  //      }
  //    });

  //}
  ////#endregion Testing SEO SECTION






  //#region Testing SEO SECTION
  //setSEOTags() {
  //  this.metaTagService.addTags([
  //    { name: "description", content: "" },
  //    { name: "title", content: '' },
  //    { name: 'robots', content: 'index, follow' },
  //    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  //  ]);


  //  this.router.events
  //    .pipe(
  //      tap((item: any) => {
  //      }),
  //      map((item: any) => {
  //        return item instanceof NavigationEnd
  //      }
  //      ),
  //    )
  //    .subscribe((e: any) => {
  //      debugger;
  //      this.homeService.setcurrentURL(e.url);
  //    });







  //  this.router.events.subscribe((e) => {
  //    if (e instanceof NavigationEnd) {
  //      this.homeService.setcurrentURL(e.url);
  //      if (e.url == '/home') {
  //        this.testing('HOME');
  //      }
  //    }
  //  });

  //  //this.ap.seoTagsData$.subscribe((response: any) => {
  //  //  if (response) {
  //  //    this.allTags$ = response;
  //  //  }
  //  //});

  //  //this.allTags$ = this.ap.seoTagsData$.pipe(
  //  //  startWith(this.ts.get(Tags_Data_Key, [])),
  //  //);

  //  //this.allTags$
  //  //  .pipe(
  //  //    tap((item: any) => {
  //  //      this.ts.set(Tags_Data_Key, item);



  //  //    }),
  //  //    map((item: any) => {
  //  //      return item.filter((x: any) => {
  //  //        let fc: string;
  //  //        let route: any;
  //  //        this.homeService.currentURLObserver.subscribe((res) => {
  //  //          route = res;
  //  //        });
  //  //        if (route == "/home") {
  //  //          fc = 'HOME';
  //  //          return x.FeatureCode == fc;
  //  //        }
  //  //        return;
  //  //      })[0];
  //  //    }
  //  //    ),
  //  //  )
  //  //  .subscribe((tags: any) => {
  //  //    this.CurrentTags$ = tags;
  //  //    let title = "";
  //  //    (tags == undefined || (tags?.Title == "")) ? title = "Home" : title = tags.Title;
  //  //    this.titleService.setTitle(title);
  //  //    if (tags != null && tags != undefined) {
  //  //      this.metaTagService.updateTag({ name: 'description', content: tags.MetaDescription });
  //  //      this.metaTagService.updateTag({ name: 'title', content: tags.MetaTitle });
  //  //    }
  //  //    else {
  //  //      this.metaTagService.updateTag({ name: 'title', content: "" });
  //  //      this.metaTagService.updateTag({ name: 'description', content: "" });
  //  //    }


  //  //  });

  //}
  //#endregion Testing SEO SECTION




  test() {

  }

  //testing(code: string) {

  //  this.ap.seoTagsData$.subscribe((response: any) => {
  //    if (response) {
  //      this.allTags$ = response;
  //    }
  //  });

  //  this.allTags$ = this.ap.seoTagsData$.pipe(
  //    startWith(this.ts.get(Tags_Data_Key, [])),
  //  );

  //  this.allTags$
  //    .pipe(
  //      tap((item: any) => {
  //        this.ts.set(Tags_Data_Key, item);
  //      }),
  //      map((item: any) => {
  //        return item.filter((x: any) => {
  //          return x.FeatureCode == code;
  //        })[0];
  //      }
  //      ),
  //    )
  //    .subscribe((tags: any) => {
  //      this.CurrentTags$ = tags;
  //      let title = "";
  //      (tags == undefined || (tags?.Title == "")) ? title = "Home" : title = tags.Title;
  //      this.titleService.setTitle(title);
  //      if (tags != null && tags != undefined) {
  //        this.metaTagService.updateTag({ name: 'description', content: tags.MetaDescription });
  //        this.metaTagService.updateTag({ name: 'title', content: tags.MetaTitle });
  //      }
  //      else {
  //        this.metaTagService.updateTag({ name: 'title', content: "" });
  //        this.metaTagService.updateTag({ name: 'description', content: "" });
  //      }


  //    });
  //}




  //setSEOTags() {
  //  this.ap.seoTagsData$.subscribe((response: any) => {
  //    if (response) {
  //      this.allTags$ = response;
  //    }
  //  });



  //  this.allTags$ = this.ap.seoTagsData$.pipe(
  //    startWith(this.ts.get(Tags_Data_Key, [])),
  //  );


  //  this.allTags$
  //    .pipe(
  //      tap((item: any) => {
  //        if (item) {
  //          this.ts.set(Tags_Data_Key, item);
  //        }
  //      }),
  //      map((item: any) => {
  //        if (item) {
  //          return item.filter((x: any) => { return x.FeatureCode == 'RQ_AE' })[0];
  //        }
  //      }
  //      ),
  //    )
  //    .subscribe((tags: any) => {
  //      this.CurrentTags$ = tags;
  //      let title = "";
  //      (tags == undefined || (tags?.Title == "")) ? title = "Report an Adverse Event" : title = tags.Title;
  //      this.titleService.setTitle(title);
  //      if (tags != null && tags != undefined) {
  //        this.metaTagService.updateTag({ name: 'description', content: tags.MetaDescription });
  //        this.metaTagService.updateTag({ name: 'title', content: tags.MetaTitle });
  //      }
  //      else {
  //        this.metaTagService.updateTag({ name: 'title', content: "" });
  //        this.metaTagService.updateTag({ name: 'description', content: "" });
  //      }
  //    });
  //}







}



