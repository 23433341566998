import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { UserProfileAccessDetails } from 'src/app/_models/password-policy';
import { PortalComponents, PortalProfiles } from 'src/app/_models/portals';
import { UserLoginResult } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { HomeService } from 'src/app/_services/home.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppConstants } from 'src/shared/AppConstants';
import { ACCESSTYPES } from '../../_enums/constant-types.enum';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  LoginStatus$: Observable<boolean>;
  UserName$: Observable<string>;
  UserFullName$: Observable<string>;
  isUS_HCP: boolean = true;
  enableLoginItems: boolean;
  enabledMenuItems: any;
  PortalComponents?: PortalComponents[];
  featuresList: any;
  selectedList: any;
  platformId: any;

  constructor(private authService: AuthenticationService,
    private utilityService: UtilityService,
    private homeService: HomeService,
    private router: Router,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(PLATFORM_ID) platformId: any) {
    this.platformId = platformId;
    this.enableLoginItems = false;
  }

  ngOnInit(): void {
    this.homeService.selectedProfileObserver.subscribe((response) => {
      if (response) {
        this.selectedList = response;
        if (this.selectedList && (this.selectedList.ACCESS_TYPE_CODE == ACCESSTYPES.GUEST && this.selectedList.IS_LOGIN_OPTIONAL) || (this.selectedList.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED)) {
          this.enableLoginItems = true;
        }
        else {
          this.enableLoginItems = false;
        }
      }
    });
    this.enabledMenuItems = AppConstants.enabledMenuItems;
    this.LoginStatus$ = this.authService.isLoggedIn;
    this.featuresList = this.homeService.userProfileFeatureList;
    this.homeService.featureProfileObserver.subscribe((response) => {
      this.featuresList = response;
      this.enabledMenuItems = [];
      if (this.featuresList && this.featuresList.length > 0) {
        for (let index = 0; index < this.featuresList.length; index++) {
          const component = this.featuresList[index];
          switch (component.Code) {
            case 'SR_MI':
              this.enabledMenuItems.isSMI = component.IS_ACTIVE;
              break;
            case 'RQ_MI':
              this.enabledMenuItems.isRMI = component.IS_ACTIVE;
              break;
            case 'RQ_AE':
              this.enabledMenuItems.isAE = component.IS_ACTIVE;
              break;
            case 'RQ_PC':
              this.enabledMenuItems.isPC = component.IS_ACTIVE;
              break;
            case 'F_MSL':
              this.enabledMenuItems.isMSL = component.IS_ACTIVE;
              break;
            case 'EV_CF':
              this.enabledMenuItems.isConferences = component.IS_ACTIVE;
              break;
            case 'NS_AN':
              this.enabledMenuItems.isNews = component.IS_ACTIVE;
              break;
            case 'OR_PD':
              this.enabledMenuItems.isProducts = component.IS_ACTIVE;
              break;
            case 'RESO':
              this.enabledMenuItems.isResources = component.IS_ACTIVE;
              break;
          }
        }
      }
    });
    this.UserName$ = this.authService.currentUserName;
    this.UserFullName$ = this.authService.currentUserFullName;
    //this.utilityService.isUS_HCP_Data.subscribe((res) => {
    //  this.isUS_HCP = res;
    //});
  }

  navigateToUserProfile() {
    this.router.navigateByUrl('/my-profile');
  }
  navigateToBookmarkedContent() {
    this.router.navigateByUrl('/bookmarked-content');
  }

  logout() {
    //this.authService.loginStatus.next(false);
    //this.authService.UserName.next("");
    //this.router.navigate(['/'])
    //  .then(() => {
    //    window.location.reload();
    //  });
    let IsSessionTimeOut = false;
    this.authService.logoutResponse(IsSessionTimeOut).subscribe((response) => {
      console.log('logout-res: ', response);
      this.authService.logout();
      this.authService.stopRefreshTokenTimer();
      this.authService.loginStatus.next(false);
      this.utilityService.isProfileSelection = true;
      this.utilityService.actionOnSelfQualification(false);
      this.authService.UserFullName.next("");
      this.router.navigate(['/'])
        .then(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.location.reload();
          }

        });
    });


  }

  navigateToUserProfileComponent() {
    this.router.navigate(['/my-profile']);
  }

  login() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(LoginComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.onLogin.subscribe((result: UserLoginResult) => {
      if (result != null) {
        this.authService.loginStatus.next(true);
        this.authService.UserFullName.next(result.UserFullName ?? "");
        this.authService.hcpDetails.next(this.utilityService.getAppUserInfoObject(result));
      }
    });
  }

  register() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-xl modal-dialog-centered',
      keyboard: false,
    };
    this.modalRef = this.modalService.show(RegisterComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
  }

  openContactModal(template: TemplateRef<any>) {
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(template, initialState);
  }

}
