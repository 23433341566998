

<footer>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center pb-3">

        <a *ngIf="!(LoginStatus$ | async) as LoginStatus" href="javascript:void(0)" class="text-white d-inline-block fs-13 text-underline pb-2"
           (click)="OnNotProfileClick()"><i id="{{LoginStatus}}" class="fas fa-sign-out-alt fs-16"></i> &nbsp; Not a {{profilename}}?</a>

        <!--<div *ngIf="!loginstatus">
          <a href="javascript:void(0)" class="text-white d-inline-block fs-13 text-underline pb-2"
             (click)="OnNotProfileClick()"><i class="fas fa-sign-out-alt fs-16"></i> &nbsp; Not a {{profilename}}?</a>

        </div>-->

        <p class="fs-12 text-white pt-3">This website is intended to provide medical information relating to questions asked about {{utilityService.getPortalName()}}. This website contains information on products which is targeted to a wide range of audiences and could contain product details or information otherwise not accessible or valid in your country.  we do not take any responsibility for accessing such information which may not comply with any legal process, regulation, registration or usage in the country of your origin. If you are a patient on any {{utilityService.getPortalName()}} medicine, please contact your treating doctor for medical support and before making any changes to your treatment</p>
      </div>
    </div>
    <div class="row border-bottom pb-2">
      <div class="col-md-12">
        <div class="footerLinks text-center">
          <a *ngIf="enabledFooterItems.isResources" routerLink="resources">Resources</a>
          <a href="https://www.scimaxglobal.com/privacy-policy/" target="_blank">Privacy Statement</a>
          <a href="javascript:void(0)" (click)="openContactModal(template)">Contact Us</a>
          <a href="https://www.techsolcorp.com/application-privacy-notice/" target="_blank">Terms of Use</a>
          <a *ngIf="enabledFooterItems.isSitemap" routerLink="sitemap">Sitemap</a>
          <a href="javascript:void(0)" (click)="openCookie()">Customize Cookie Settings</a>
        </div>
      </div>
    </div>
    <!-- <div class="row pt-2">
        <div class="col-md-12">
             <ul class="quicklinks">
             <a href="" class="text-white d-block fs-13 text-underline pb-2">Copyright information - © 1996-2021 {{utilityService.getPortalName()}}. All Rights Reserved</a>
             </ul>
        </div>
    </div> -->
    <div class="row pt-2">
      <div class="col-md-10 col-12">
        <div class="row">
          <div class="col-md-3 col-12"></div>
          <div class="col-md-9 col-12">
            <p class="text-white d-block fs-13 pt-2 text-center">
              Copyright information - © 1996-{{copyrightCurrentYear}}
              {{utilityService.getPortalName()}}. All Rights Reserved
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-12">
        <p class="text-white fs-9 footer-logo">
          Powered by<a href="https://www.scimaxglobal.com/" target="_blank">
            <img src="./assets/images/scimax.png" alt="">
          </a>
        </p>
      </div>
    </div>
  </div>
</footer>

<ng-template #template>
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block">
      Contact Us
      <span class="mt-1 float-right fs-13" data-dismiss="modal" (click)="modalRef.hide()">
        <i class="fas fa-times"></i>
      </span>
    </h5>
  </div>
  <div class="modal-body px-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 col-md-12">
          <ul class="contactUs">
            <li>
              <div class="row">
                <div class="col-md-12">
                  <span class="text-center"><i class="fas fa-phone-alt"></i></span>
                  <small>000-000-0000 (000-00-ALPHA) available on Monday – Friday from 9am - 7pm.</small>
                </div>
                <div class="col-md-12">
                  <span class="text-center"><i class="fas fa-envelope"></i></span>
                  <a href="mailto:medinfo@alpharma.com?subject=Medical%20Information%20Request" class="d-block fs-13">medinfo@alphapharma.com</a>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
