import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Occupation } from 'src/app/_models/msl';
import { Portal, PortalProfiles } from 'src/app/_models/portals';
import { ProductsAutoComplete } from 'src/app/_models/products';
import { MedicalInfoService } from 'src/app/_services/medical-info.service';
import { AppConstants } from '../../../shared/AppConstants';
import { NameValidator } from '../../directives/no-white-spaces.directive';
import { CP_VW_LOOKUPS } from '../../_models/cpvw-lookups';
import { AuthenticationService } from '../../_services/authentication.service';
import { HomeService } from '../../_services/home.service';
import { OurProductsService } from '../../_services/our-products.service';
import { SystemConfigService } from '../../_services/system-config.service';
import { UtilityService } from '../../_services/utility.service';
import { filter } from 'rxjs/operators';
import { FrequentlyUsedKewordsRequest, FrequentlyUsedKewordsResponse } from '../../_models/ContentInformation';
import { AlertifyService } from '../../_services/alertify.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/core';
import { map, startWith, BehaviorSubject, combineLatest, tap } from 'rxjs'
import { HttpClient } from '@angular/common/http';
enum FilterState {
  ALL = 'All',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
}

const SEO_TAGS_DATAKEY: any = makeStateKey<any>('SsrData');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;

  isProductInvalid: boolean = false;
  typeaheadNoResults: boolean = false;
  productTypeaheadLoading: boolean;
  selectedProduct: string | '' = '';
  typeaheadHideResultsOnBlur: boolean = true;

  allProducts: ProductsAutoComplete[] = [];
  productDataSource$: Observable<ProductsAutoComplete[]>;
  private subscribeDisplayProducts: Subscription | null = null;
  SearchMIForm: FormGroup;

  keywordvalidationmsg: string;
  prodcutvalidationmsg: string;
  profesionvalidationmsg: string;
  Ismiq: boolean = true;
  prodmaptype: string = "";
  isLoggedInUser: boolean = false;
  //occupationList: Occupation[] = [];
  occupationList: CP_VW_LOOKUPS[] = [];
  user_id?: string = "";
  portal_id?: string = "";
  enableReportAE: boolean = false;
  enableSMI: boolean = false;
  portal: Portal = {
    PortalComponents: []
  };
  freq_keywords: FrequentlyUsedKewordsResponse;

  subscribeGetPortal: Subscription | null = null;
  platformId: any;
  CurrentTags: any; CurrentTags_SM: any; CurrentTags_RA: any;
  TagsData: any;
  constructor(
    private ourProductsService: OurProductsService,
    public utilityService: UtilityService,
    private homeService: HomeService,
    private authService: AuthenticationService,
    public fb: FormBuilder,
    private route: Router,
    public activaterouter: ActivatedRoute,
    private configService: SystemConfigService,
    private titleService: Title, private metaTagService: Meta,
    private medInfoService: MedicalInfoService,
    private transferState: TransferState,
    private alertfyService: AlertifyService, @Inject(PLATFORM_ID) platformId: any,
    private http: HttpClient
  ) {
    this.platformId = platformId;
    this.setSEOTags();
    this.SearchMIForm = this.fb.group({
      ProductApprovalId: new FormControl("", [Validators.required, Validators.maxLength(150)]),
      SearchTags: [null, [Validators.required, Validators.maxLength(100), NameValidator.noWhiteSpace]],
      profession: [null, [Validators.required]]
    });

    this.authService.hcpDetails.subscribe(next => {
      if (next && next.FK_PROFESSION_ID != undefined) {
        this.user_id = next.PK_UserId?.toString();
        let professionid = next.FK_PROFESSION_ID?.toString();
        this.SearchMIForm.get('profession')?.setValue(professionid);
      }
    });
  }



  ngOnInit(): void {
    this.homeService.setRouterUrl(this.route.url);
    this.authService.setCurrentFeatureCode("HOME");
    this.isLoggedInUser = this.authService.isUserLoggedIn();
    if (this.isLoggedInUser) {
      var userDetails = this.authService.getLoginHCPDetails();
      this.user_id = userDetails.PK_UserId?.toString();
      var profesionid = userDetails.FK_PROFESSION_ID?.toString();
      this.SearchMIForm.get('profession')?.setValue(profesionid);
    }
    this.homeService.featureProfileObserver.subscribe((response) => {
      if (response && response.length > 0) {
        let isSMIExist = response.findIndex((profile: any) => profile.Code == 'SR_MI');
        if (isSMIExist != -1) {
          this.enableSMI = true;
        }

        let isAEExist = response.findIndex((profile: any) => profile.Code == 'RQ_AE');
        if (isAEExist != -1) {
          this.enableReportAE = true;
        }
      }
    });

    this.prodmaptype = this.utilityService.getProductsMappingType();
    if (this.prodmaptype) {
      if (this.prodmaptype == "HYBRID_MIQ" || this.prodmaptype == "CP_MIQ") {
        this.Ismiq = true;
      }
      else {
        this.Ismiq = false;
      }
    }


    this.getProducts();
    this.getPortal();
    this.getProfessionsList();

    // if (this.authService.isCookie()) {

    //   this.authService.cookieTracking("HOME").subscribe();
    // }
    if (this.authService.isOneTrustCookie()) {
      this.authService.cookieTracking("HOME").subscribe();
    }
  }

  get form() {
    return this.SearchMIForm.controls;
  }

  get enableSearch() {
    let isValid = true;
    if (!this.SearchMIForm.controls["ProductApprovalId"].value) {
      isValid = false;
    }
    return isValid;
  }

  get enableProceed() {
    let isValid = true;
    if (!this.SearchMIForm.controls["profession"].value) {
      isValid = false;
    }
    return isValid;
  }


  getPortal() {
    this.subscribeGetPortal = this.homeService.getPortal().subscribe({
      next: (result) => {
        this.portal = result;
        this.authService.portal = result;
        this.utilityService.portalTitle = this.portal.PortalTitle;
        this.utilityService.reporterTypeName = result.ReporterTypeName ?? "I am a Healthcare Professional";
        this.utilityService.nonHcpReporterTypeName = result.NonHcpReporterTypeName ?? "I am a Patient / Caregiver";

        //if (!this.utilityService.isProfileSelected()) {
        //  this.utilityService.isProfileSelection = true;
        //}
        this.utilityService.setEnvironmentStorage(result);
        this.blockUI?.stop();
      },
      error: (err) => {
        this.blockUI?.stop();
        console.error(err);
      }
    });
  }

  getProducts() {
    this.allProducts = [];
    this.subscribeDisplayProducts = this.ourProductsService.getProductsTypeHeadData().subscribe({
      next: (result) => {
        this.allProducts = result;
      },
      error: (err) => {
        console.error(err);
      }
    });
    this.productDataSource$ = Observable.create((observer: any) => {  // Runs on every search
      observer.next(this.selectedProduct);
    }).pipe(
      switchMap((searchKey: string) => this.ourProductsService.searchProducts(this.allProducts, searchKey)));
  }

  getProfessionsList() {
    this.occupationList = [];
    let profiletype_id = "";
    let profileinfo: PortalProfiles = {};
    this.homeService.selectedProfileObserver.subscribe((response) => {
      if (response) {
        profileinfo = response;
        if (profileinfo) {
          profiletype_id = profileinfo.PK_ENV_PROFILE_ID ?? '';
        }
        if (profiletype_id) {
          this.configService.getProfessionByProfile(profiletype_id).subscribe({
            next: (result) => {
              this.occupationList = result;
            },
            error: (err) => {
              console.error(err);
              this.occupationList = [];
            }
          });
        }
      }
    });
  }

  ProceedToAE() {
    if (this.validateproceed()) {
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0);
      }
      this.route.navigate(['/report-ae'], { queryParams: { Pid: this.SearchMIForm.get('profession')?.value } });
    }
  }

  OnSearchClick() {
    this.cleanForm();
    if (this.validateForm()) {
      this.route.navigate(['/find-medical-info'], { queryParams: { ProductApprovalId: this.SearchMIForm.get('ProductApprovalId')?.value, SearchTags: this.SearchMIForm.get('SearchTags')?.value } });
    }
  }

  onProductSelect($event: TypeaheadMatch): void {

  }

  productTypeaheadOnBlur($event: any): void {
    this.isProductInvalid = false;
    if ($event.item && typeof $event.item === 'object' && $event.item !== null && $event.item?.PK_PRODUCT_MAPPING_ID != '') {
      //this.AgreementSearchParams.ProductID = $event.item.PK_PRODUCT_MAPPING_ID;
      this.selectedProduct = $event.item.PRODUCT_DISPLAY_NAME;
      this.typeaheadNoResults = false;
    } else {
      //this.AgreementSearchParams.ProductID = null;
      this.typeaheadNoResults = true;
      this.selectedProduct = '';
      if ($event.srcElement) $event.srcElement.value = '';
    }
  }

  productTypeaheadNoResults($event: any) {
    if ($event && typeof $event.item === 'object' && $event.item !== null && $event.item?.PK_PRODUCT_MAPPING_ID != '') {
      //this.AgreementSearchParams.ProductID = null;
      this.isProductInvalid = true;
      this.typeaheadNoResults = true;
    }
    else {
      if (this.selectedProduct == '') {
        this.isProductInvalid = false;
        this.typeaheadNoResults = false;
        //this.AgreementSearchParams.ProductID = null;
      }
    }
  }

  changeProductTypeaheadLoading(e: any): void {
    this.productTypeaheadLoading = e;
  }

  setProduct($event: any) {
    console.log($event);
    alert("changed");
  }

  validateForm(): boolean {
    this.keywordvalidationmsg = "";
    this.prodcutvalidationmsg = "";
    let hasValid = true;

    if (!this.SearchMIForm.get('ProductApprovalId')?.value) {
      this.prodcutvalidationmsg = "Please select Product.";
      return hasValid = false;
    }

    if (this.Ismiq) {
      if (this.SearchMIForm.get('SearchTags')?.value && this.SearchMIForm.get('SearchTags')?.value.length < 2) {
        this.keywordvalidationmsg = "Please enter at least 2 characters to perform search.";
        return hasValid = false;
      }
      if (this.SearchMIForm.get('SearchTags')?.value && this.SearchMIForm.get('SearchTags')?.errors?.maxlength) {
        this.keywordvalidationmsg = "Keyword(s) field exceeded Max length of 100.";
        return hasValid = false;
      }

    }
    else {
      if (this.SearchMIForm.get('SearchTags')?.value && (this.SearchMIForm.get('SearchTags')?.errors?.noWhiteSpace)) {
        this.keywordvalidationmsg = "Please enter only one keywords(s) to perform search.";
        return hasValid = false;
      }

      if (this.SearchMIForm.get('SearchTags')?.value && this.SearchMIForm.get('SearchTags')?.value.length < 2) {
        this.keywordvalidationmsg = "Please enter at least 2 characters to perform search.";
        return hasValid = false;
      }
      if (this.SearchMIForm.get('SearchTags')?.value && this.SearchMIForm.get('SearchTags')?.errors?.maxlength) {
        this.keywordvalidationmsg = "Keyword(s) field exceeded Max length of 100.";
        return hasValid = false;
      }
      if (this.SearchMIForm.get('SearchTags')?.value) {
        var IsSpecailCharExists = new RegExp(/^[a-z0-9]+$/i);
        var searchtag = this.SearchMIForm.get('SearchTags')?.value;
        if (!IsSpecailCharExists.test(searchtag)) {
          this.keywordvalidationmsg = "Specail characters not allowed in Keywords(s).";
          return hasValid = false;
        }
      }
    }
    return hasValid;
  }

  validateproceed(): boolean {
    this.profesionvalidationmsg = "";
    let hasValid = true;

    if (!this.SearchMIForm.get('profession')?.value) {
      this.profesionvalidationmsg = "Please select profession.";
      return hasValid = false;
    }

    return hasValid;
  }

  public cleanForm() {
    let count = 0;
    if (this.SearchMIForm != null && this.SearchMIForm != undefined) {
      Object.keys(this.SearchMIForm.controls).forEach(key => {

        let value: any = this.SearchMIForm.controls[key].value;
        if (this.SearchMIForm.controls[key].value != null && typeof (this.SearchMIForm.controls[key].value) === "string"
          && this.SearchMIForm.controls[key].value !== "") {
          value = this.SearchMIForm.controls[key].value.trim();
        }
        else if (this.SearchMIForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        value = this.utilityService.replaceEmojis(value);

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE" && key != "ProductApprovalId") {
          this.SearchMIForm.controls[key].setValue(value);
        }

      });
    }
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.SearchMIForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }
    return true;
  }

  FetchFrequentlyUsedProductKeywords(res: boolean) {
    if (res) {
      this.freq_keywords = {};
      this.SearchMIForm.get('SearchTags')?.setValue("");
      let searchdata: FrequentlyUsedKewordsRequest = {
        ProductId: this.SearchMIForm.controls["ProductApprovalId"].value,
      };
      this.medInfoService.FetchFrequentlyUsedProductKeywords(searchdata).subscribe({
        next: (result) => {
          if (result && result.Status) {
            this.freq_keywords.Keywords = result.Keywords;
          }
          else {
            this.alertfyService.errorBaner(result.ErrorMessage ?? '');
          }
        },
        error: (err) => {
          this.alertfyService.errorBaner(err);
        }
      });
    }
  }

  KeySelected(keyword: string) {
    this.SearchMIForm.get('SearchTags')?.setValue(keyword);
    this.OnSearchClick();
  }

  setSEOTags() {
    this.CurrentTags = this.utilityService.getCurrentPageTags("HOME");
    this.CurrentTags_SM = this.utilityService.getCurrentPageTags("SR_MI");
    this.CurrentTags_RA = this.utilityService.getCurrentPageTags("RQ_AE");
    let title = "";
    (this.CurrentTags == undefined || (this.CurrentTags?.Title == "")) ? title = this.utilityService.getPortalName() : title = this.CurrentTags.Title;
    this.titleService.setTitle(title);

    if (this.CurrentTags != null && this.CurrentTags != undefined) {
      if (isPlatformBrowser(this.platformId)) {
        this.metaTagService.updateTag({ name: 'title', content: this.CurrentTags.MetaTitle });
        this.metaTagService.updateTag({ name: 'description', content: this.CurrentTags.MetaDescription });
      }
    }
    else {
      if (isPlatformBrowser(this.platformId)) {
        this.metaTagService.updateTag({ name: 'title', content: "" });
        this.metaTagService.updateTag({ name: 'description', content: "" });
      }
    }
  }

}
